#page-about {

	/* Text content */
	.page-content {
		margin-bottom: 0;
		width: 100%;
		background-color: var(--light3);
	}

	.all-content {
		max-width: 1440px;
		width: calc(100vw - 120px);
		padding: 15px 60px 40px 60px;
	}

	.content h3 {
		margin: 40px 0 10px 0 !important;
	}

	.list-menu .menu-content {
		width: 360px;
	}

	.content {
		a {
			margin-left: 12px;
			line-height: 26px;
		}

		.sub-content {
			margin-bottom: 20px;

			a:first-of-type {
				color: var(--dark6);
			}

			a:last-of-type {
				margin-left: 25px;
			}
		}

		.text-emp {
			font-weight: bold;
			color: var(--main);
		}
	}

	/* Table */
	.table {
		align-self: center;
		margin-left: 12px;
		border-bottom: 1px solid var(--dark2);

		div {
			margin-top: -1px;
			background-color: var(--light3);
			border: 1px solid var(--dark2);
			border-bottom: none;
			padding: 15px 20px;
		}

		div:hover {
			background-color: var(--light5);
		}

		a {
			margin-left: 0;
			cursor: default;
		}

		a:first-of-type {
			width: 70px;
			text-decoration: underline;
			color: var(--dark6);
		}

		a:last-of-type {
			width: calc(100% - 70px);
		}
	}

	/* Image */
	.image a {
		margin-top: 7px;
		width: 340px;
	}

	/* Brands */
	.brands {
		.brand-type {
			padding: 25px 30px;
		}

		div {
			div {
				border: none;
				padding: 0;
				width: 65%;
				background-color: transparent;
			}

			div:hover {
				background-color: transparent;
			}
		}

		a {
			width: auto !important;
		}

		a:first-of-type {
			font-size: 20px;
			margin-bottom: 3px;
		}

		span {
			font-weight: bold;
			margin-right: 5px;
		}

		img {
			width: 30%;
		}
	}

	/* Certificates */
	.certificates {
		margin-top: 25px;

		div {
			width: 30%;
		}

		a {
			line-height: 22px;
		}

		a:first-of-type {
			font-weight: bold;
		}

		img {
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
		}
	}

	.certificate-mul {
		width: 100% !important;

		img {
			width: 30%;
		}
	}

	.certificate-last {
		margin-left: 5%;
		width: 40% !important;
	}

	.modal {
		.modal-content {
			width: 600px;
			height: 800px;

			.close {
				align-self: flex-end;
				justify-self: flex-start;
				margin: 10px 0;
				font-size: 25px;
				cursor: pointer;
			}

			div {
				height: calc(100% - 45px);

				img {
					height: 100%;
				}
			}
		}

		.modal-content.img-hztl {
			width: 80vw;
			height: 500px;
		}
	}

	@media only screen and (max-width: 1125px) {
		.all-content {
			.content h3 {
				text-align: center;
			}

			.content#content-certificate {
				a {
					margin: 2px 0;
					text-align: center;
				}

				.brands a:first-of-type {
					font-size: 24px;
					font-weight: bold;
					text-decoration: none;
					text-align: center;
				}

				.brands {
					.brand-type {
						width: calc(100% - 60px);
					}

					a {
						text-align: left;
					}

					img {
						margin-top: 10px;
						width: 180px;
					}
				}
			}

			.brands {
				align-items: center;

				.brand-type {
					flex-direction: column;
				}

				div div {
					width: 100%
				}
			}
		}

		.modal {
			.modal-content {
				width: 580px;
			}

			.modal-content.img-hztl {
				width: 90vw;
				height: 400px;
			}
		}
	}

	@media only screen and (max-width: 925px) {
		.all-content {
			padding: 30px 0;
		}

		h2 {
			margin-top: 0;
		}
	}

	@media only screen and (max-width: 600px) {
		.modal {
			.modal-content {
				width: 300px;
				height: 450px;
			}

			.modal-content.img-hztl {
				width: 100vw;
				height: 260px;
			}
		}
	}

	@media only screen and (max-width: 500px) {
		.page-content {
			margin: 0;
			min-height: auto;
		}

		.all-content {
			width: calc(100vw - 40px);
			margin: 0;
		}

		.content {
			width: 100%;

			a {
				margin: 0;
			}
		}

		.table {
			margin: 0;
			width: 90vw;

			a:last-of-type {
				width: 100%;
			}
		}

		.image {
			img {
				max-width: 90vw;
			}

			a {
				width: 100%;
			}
		}

		.brands {
			span::after {
				content: "";
				display: block;
			}

			.brand-type a:nth-of-type(3) {
				margin-left: -5px !important;

				span {
					margin-left: 5px !important;
				}
			}
		}
	}
}