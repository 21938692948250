#page-order-complete {
    .page-content {
        width: 100vw;
        padding: 15px 60px 40px 60px;
        background-color: var(--light3);

        .content {
            align-self: flex-start;
            margin: 35px 0 40px 0;
            width: 100%;
        }

        /* Title */
        h2 svg {
            font-size: 25px;
            color: var(--sub1);
            margin-right: 6px;
        }

        /* Thank you card */
        .thank-you-card {
            width: 400px;
            object-fit: contain;
            background-color: #2A3847;
        }

        /* Message */
        .message {
            align-self: center;
        }

        /* Order detail */
        .order-content {
            padding: 30px;
            width: 340px;
            background-color: var(--dark11-4);

            h3 {
                align-self: center;
                margin: 0 0 25px 0;
                padding: 0 15px;
                width: fit-content;
                font-size: 20px;
                text-align: center;
                background-color: var(--dark14);
                z-index: 2;
            }

            .title-line {
                align-self: center;
                width: 80%;
                border-bottom: 1px solid var(--dark5);
                transform: translateY(-38px);
                opacity: 0.7;
            }

            .field {
                margin-bottom: 15px;

                .title {
                    margin-right: 10px;
                    font-weight: bold;
                }

                .info {
                    margin: 5px 0 0 20px;
                    line-height: 25px;
                    overflow-wrap: break-word;
                    opacity: 0.7;
                }

                .info::before {
                    content: "-";
                    margin-right: 5px;
                }
            }
        }

        /* Button */
        .button1 {
            border-color: var(--warning);
            border-radius: 0;
            padding: 10px 20px;
            font-size: 16px;
            background-color: var(--warning);
        }

        .button1:hover {
            color: var(--warning);
            background-color: transparent;
        }
    }

    @media only screen and (max-width: 975px) {
        .page-content {
            .content {
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
            }

            .order-content {
                min-height: fit-content;
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .page-content {
            .message {
                width: 80vw;
                text-align: center;
            }

            .thank-you-card {
                width: 90vw;
            }

            .order-content {
                width: calc(90vw - 60px);
            }
        }
    }
}