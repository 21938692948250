#page-product-list {
	.page-content {
		margin: 0;
		width: 100vw;
		background-color: var(--light3);
	}

	#category-side-bar {
		padding: 30px 45px;
	}

	.list {
		padding: 30px 45px 50px 0;
		width: 100%;

		/* Title row */
		.title-row {
			margin-bottom: 30px;
		}

		/* Selected category */
		.category-select {
			font-weight: bold;
			font-size: 24px;
		}

		/* Dropdown */
		.dropdown {
			margin: 0 13px 0 15px;
			width: 130px;
			position: relative;
			cursor: pointer;

			svg {
				margin-left: 10px;
			}
		}

		.dropdown-text {
			border: 1px solid var(--dark5);
			padding: 5px 8px 5px 20px;
		}

		#dropdown-text {
			font-weight: bold;

			a {
				padding: 0;
				text-align: center;
			}
		}

		.dropdown-content {
			display: none;
			flex-direction: column;
			position: absolute;
			text-align: left;
			margin: 102px 0 0 -21px;
			border: 1px solid var(--dark5);
			width: 128px;
			z-index: 2;

			a {
				padding: 4px 0 4px 20px;
				width: 108px;
				background-color: var(--light3);
			}

			a:first-of-type {
				padding-top: 8px;
			}

			a:last-of-type {
				padding-bottom: 8px;
			}

			a:hover {
				background-color: var(--light4);
			}
		}

		.sort-icon {
			font-size: 23px;
			cursor: pointer;
		}

		/* Sort */
		.sort-icon:hover {
			color: var(--sub1);
		}
	}

	.product-list {
		display: grid;
		flex-wrap: wrap;
		grid-template-columns: repeat(auto-fill, 343px);
	}

	.product {
		margin-top: 10px;
		border: 1px solid transparent;
		padding: 0 20px 30px 20px;
		cursor: pointer;
	}

	.product:hover {
		border: 1px solid var(--dark2);
		box-shadow: 0px 0px 15px var(--shadow);
	}

	.product-img {
		width: 300px;
		height: 200px;
		background-color: var(--dark2);

		img {
			width: 300px;
			height: 200px;
			object-fit: scale-down;
			background-color: var(--light3);
		}
	}

	.product-name {
		margin-bottom: 5px;
		font-weight: bold;

		span {
			margin-left: 7px;
			font-weight: 500;
		}
	}

	.price-cont {
		a {
			align-items: baseline;
			width: fit-content;

			span {
				font-size: 16px;
			}

			span.currency {
				padding-right: 5px;
			}

			span.number {
				font-size: 20px;
				letter-spacing: -1px;
			}
		}

		a.strikethrough {
			position: relative;
			color: var(--dark13);
			font-weight: 300;
		}

		a.strikethrough::after {
			content: '';
			position: absolute;
			left: -3px;
			top: 50%;
			width: calc(100% + 6px);
			height: 1.5px;
			background: var(--dark13);
			transform: translateY(-50%);
		}


		.product-sale {
			margin-left: 15px;
			color: var(--red);
		}
	}

	.color_container {
		margin-top: 8px;
	}

	.color {
		margin: 0 4px;
		width: 23px;
		height: 20px;
		opacity: 0.8;
	}

	.color:hover {
		opacity: 1;
	}

	/** Loader **/
	.loader-cont {
		margin-top: 20px;
		width: 100%;
	}

	@media only screen and (max-width: 1400px) {
		.page-content {
			justify-content: center;
		}

		.list {
			padding-right: 0;
			width: 90vw;
		}
	}

	@media only screen and (max-width: 1143px) {
		.product-list {
			grid-template-columns: repeat(auto-fill, 280px);
		}

		.page-content.search-result .product-list.no-result {
			grid-template-columns: none;
		}
	}

	@media only screen and (max-width: 975px) {
		.page-content {
			flex-direction: column;
			align-items: center;
		}

		.page-content.search-result {
			flex-direction: row;
			align-items: flex-start;
		}

		#category-side-bar {
			display: none !important;
		}

		.list {
			padding: 30px 0 50px 0;

			.title-row {
				align-self: center;
				width: 85vw;
			}
		}
	}

	@media only screen and (max-width: 933px) {
		.list {
			width: 95vw;
			padding: 45px 0;
		}
	}

	@media only screen and (max-width: 884px) {
		.product-list {
			grid-template-columns: repeat(auto-fill, 185px);
		}

		.product {
			padding: 0 0 30px 0;
		}

		.product-img,
		.product-img img {
			width: 185px;
			height: 122px;
		}

		.product-name {
			display: flex;
			flex-direction: column;
			text-align: center;
		}
	}

	@media only screen and (max-width: 475px) {
		.list {
			padding-top: 20px;
			width: 90vw;

			.title-row {
				flex-direction: column;
				margin-bottom: 10px;
				width: calc(100vw - 30px);
			}

			.category-select {
				margin-bottom: 20px;
				font-size: 26px;
			}

			.sort {
				justify-content: center;
				padding: 10px 0;
				background-color: var(--dark11-4);
				font-size: 14px;
				width: 100vw;

				.dropdown {
					margin: 0 15px 0 25px;
					width: 120px;
					background-color: var(--light3);

					svg {
						margin: 0;
					}
				}

				.dropdown-content {
					margin-top: 96px;
					width: 118px;

					a {
						padding-left: 20px;
						width: 98px;
					}
				}
			}

			.product-list {
				grid-template-columns: repeat(auto-fill, 155px);
			}

			.product-img,
			.product-img img {
				width: 155px;
				height: 102px;
			}
		}
	}
}