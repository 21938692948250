#pagination {
    margin-top: 25px;
    max-width: 80vw;
    flex-wrap: wrap;

    button {
        border: none;
        background-color: transparent;
        color: blue;
        cursor: pointer;
    }

    button:hover {
        text-decoration: underline;
    }

    button.current {
        font-weight: bold;
    }
}