#page-home {
	background-color: var(--light1);

	.page-content {
		width: 100%;
		margin-bottom: 30px;
	}

	p { margin: 0; }

	/* Video Section */
	.video-section {
		padding: 66px 0;

		.title {
			font-size: 24px;
			font-weight: bold;

			div {
				height: 2px;
				width: 80vw;
				border-top: 1px solid var(--dark6-5);
				position: absolute;
			}

			p {
				margin-top: -18px;
				padding: 0 40px;
				background-color: var(--light1);
				z-index: 2;
			}
		}

		iframe {
			margin-top: 46px;
			border: 0;
			height: 500px;
			width: 888px;
			max-height: calc(80vw * 500 / 888);
			max-width: 80vw;
		}
	}

	/* Intro */
	.intro {
		width: 100%;
		padding: 50px 0;
		background-color: var(--dark12);
		opacity: 0;
		animation: show 1.5s ease-out forwards;

		h2 {
			margin: 0 0 20px 0;
			font-size: 26px;
		}

		.main-text {
			width: 90vw;
			max-width: 1125px;
			margin-bottom: 10px;

			a {
				line-height: 36px;
				font-size: 20px;
				text-align: center;
			}
		}

		.text {
			margin: 20px;
			padding: 0 10px;
			text-decoration: underline;
			text-underline-offset: 2px;
			font-size: 18px;

			svg {
				font-size: 25px;
				margin: 0 3px 3px 0;
			}

			a {
				overflow: hidden;
				cursor: default;
			}

			a::before {
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-100%);
            	transition: transform 0.5s ease;
			}
		}

		.text:hover {
			svg {
				animation: text2 0.5s linear forwards;
			}

			a {
				animation: text2 0.5s linear forwards;
			}
		}
	}

	/* Category */
	.category {
		padding: 80px 0;

		img {
			margin: 0 3.7vw;
			width: 26vw;
			max-width: 350px;
			cursor: pointer;
		}

		img:hover {
			animation: hover-button 0.5s linear forwards;
		}
	}

	@media only screen and (max-width: 925px) {
		.page-content {
			margin: 0;
		}

		/* Video Section */
		.video-section {
			margin-top: -4px;

			iframe { margin-top: 30px; }
		}

		// Intro
		.intro {
			padding: 50px 0;

			.main-text { width: 80vw; }

			.text-cont {
				justify-content: center;
				flex-wrap: wrap;
				width: 500px;
			}

			.text {
				margin: 20px 0;
				padding: 0;
			}

			.text:nth-of-type(3), .text:last-of-type { margin-top: 0; }

			.text:nth-of-type(odd) { width: 45%; }

			.text:nth-of-type(even) {
				width: 55%;
				max-width: 210px;
			}
		}


	}

	@media only screen and (max-width: 500px) {
		// Video section
		.video-section {
			padding: 30px 0;

			.title {
				.line { display: none; }
				p { margin: 0; }
			}

			iframe {
				margin: 15px 0;
				max-width: 100vw;
				max-height: calc(100vw * 500 / 888);
			}
		}

		// Intro
		.intro {
			padding: 20px 0 40px 0;

			h2 {
				margin-bottom: 10px;
				font-size: 24px;
			}

			.main-text {
				margin-bottom: 0;

				a {
					font-size: 16px;
					line-height: 28px;
				}
			}

			.text-cont {
				flex-direction: column;
				align-items: center;
				margin-top: 10px;
				width: 100%;
			}

			.text {
				margin: 5px 0 5px 25px !important;
				width: 180px !important;
				max-width: 100% !important;
				text-underline-offset: 5px;

				svg { font-size: 20px; }

				a {
					font-size: 14px;
					margin-left: 5px;
					padding: 5px;
					background-color: var(--dark5-3);
				}
			}
		}

		// Category
		.category {
			flex-direction: column;
			padding: 25px 0;

			img {
				width: 80vw;
				margin: 10px 0;
			}
		}
	}
}