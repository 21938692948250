#page-tracking {
    // margin-top: 120px;

    .page-content {
        width: 100vw;

        >.content {
            margin: 40px 0;
            border-radius: 12px;
            border: 1px solid var(--dark2);
            box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
            background-color: var(--light3);
        }
    }

    h2 {
        align-self: center;
    }

    #page-enroll h2 {
        display: none;
    }

    .content {
        max-width: 586px;
        width: 100%;
        height: 100%;
        padding: 15px 0 20px 0 !important;

        .button1,
        .button2 {
            padding: 8px 0;
            font-size: 14px;
            width: 170px;
        }

        .social {
            flex-wrap: wrap;
            justify-content: center;
            min-width: auto;
            width: 90%;
            max-width: 539px;

            button {
                margin: 0 5px 10px 5px;

                svg {
                    font-size: 18px;
                }
            }

            .search-order {
                border-color: var(--dark5);
                background-color: var(--dark5);
                color: var(--light1);
            }

            .search-order:hover {
                background-color: var(--light1);
                color: var(--dark5);
            }
        }
    }

    .content.tracking-by-number {
        max-width: 500px;
    }

    .content.sign-in-content {
        padding: 0 !important;
    }

    /* Tracking */
    .tracking {
        width: 100%;
    }

    /* sign in */
    .content.sign-in {
        margin: 0;
        border-radius: 0;
        border: 0;
        max-width: 100%;
        min-height: calc(100vh - 306px);
    }

    /* Sign out */
    .sign-out {
        .form {
            width: 100%;
            margin: 5px 0 15px 0;
            padding: 10px 0 0 0;

            input {
                background-color: var(--light3);
            }
        }

        .sign-up-content {
            margin-top: -10px;
            padding-top: 0 !important;
        }

        .tracking-form {
            padding: 0;

            div {
                margin-top: 5px;
                padding: 0;
                width: 80%;
            }

            .input-title {
                align-self: flex-start;
                margin-left: 15px;
                padding: 0 5px;
                background-color: var(--light3);
                color: var(--dark6-7);
                font-size: 15px;
                z-index: 1;
            }

            span {
                margin: 0 2px;
                color: var(--warning);
            }

            input {
                margin-top: -11.11px;
                margin-bottom: 18px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 10px 25px;
                width: calc(100% - 50px);
                height: 20px;
                font-size: 16px;
            }

            input:focus {
                outline-color: var(--sub1);
            }

            .button1 {
                margin-top: 20px;
                border: 3px solid var(--dark6);
                border-radius: 0;
                padding: 8px 0;
                background-color: var(--dark6);
                color: var(--light1);
                padding: 8px 0;
                font-size: 14px;
                width: 170px;
                transition: all 0.3s;
            }

            .button1:hover {
                background-color: var(--light1);
                color: var(--dark6);
            }

            .sign-in-search {
                margin-right: 20px;
                border-color: var(--dark5);
                background-color: var(--dark5);
            }
        }
    }

    /* Order list */
    .order-list {
        margin: 5px auto 0 auto;
        border: 1px solid var(--dark2);
        box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
        background-color: var(--light3);
        width: 90vw;
        max-width: 1000px;
        scrollbar-width: none;

        .list-content {
            .row {
                padding: 5px 0;
                color: var(--dark1);
                cursor: pointer;
            }

            .row:nth-of-type(2n) {
                background-color: var(--blue1);
            }

            .row:hover {
                background: var(--sub3);
            }
        }

        .row {
            width: 100%;

            div {
                min-width: 120px;
                max-width: 25%;
                padding: 10px 0;
                text-align: center;
            }

            .link {
                color: blue;
                cursor: pointer;
            }

            .link:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }

        .title-row {
            border-bottom: 1px solid var(--dark2);
            background-color: var(--dark11-4);
            font-weight: bold;
        }

        .no-order div {
            width: 100%;
            opacity: 0.7;
        }
    }

    /* Invalid input */
    .invalid-msg {
        margin-bottom: 18px;
        height: 12px;
        font-size: 12px !important;
        color: var(--warning) !important;
    }

    .invalid-input {
        border-color: var(--warning) !important;
        border-width: 2px !important;
        animation: shake 0.6s linear forwards;
    }

    .invalid-input:focus {
        outline: none;
    }

    @media only screen and (max-width: 1000px) {
        .sign-up.mobile {
            display: block !important;
        }

        .sign-up.desktop {
            display: none !important;
        }
    }

    @media only screen and (max-width: 925px) {
        .page-content {
            align-items: flex-start;
        }

        .content {
            border-radius: 0;
            padding: 30px 0 !important;
            width: 100%;

            h2 {
                margin-top: 0;
                z-index: 1;
            }
        }

        .content.tracking-by-number {
            justify-content: flex-start;
            max-width: 100%;

            h2 {
                margin-top: 40px;
            }
        }

        .content.sign-in {
            .order-list {
                width: 100vw;
                margin-bottom: 5px;
                border: none;
                border-top: 1.5px solid var(--dark2);
                box-shadow: none;

                .list-content {
                    .row {
                        padding: 10px 0;
                        width: fit-content;

                        div {
                            max-width: fit-content;
                            width: fit-content;
                            padding: 3px 0;

                            b {
                                margin-right: 3px;
                            }
                        }
                    }

                    .item {
                        border-bottom: 1.5px solid var(--dark2);
                    }

                    .item:nth-of-type(2n) {
                        background-color: var(--blue1);
                    }

                    svg {
                        margin-right: 15px;
                        font-size: 60px;
                    }
                }

                .title-row {
                    display: none !important;
                }
            }
        }

        .sign-out {
            justify-content: center;
            margin: 0 !important;
            border: none !important;
            border-radius: 0 !important;
            max-width: 100%;
            min-height: calc(100vh - 306px);

            #page-enroll {
                background-color: inherit !important;
            }

            .sign-in-form {
                display: none !important;
            }

            .form {
                width: 100%;
                border: 0;
                padding: 0;
            }
        }
    }

    @media only screen and (max-width: 620px) {
        .page-content {
            >.content {
                margin: 0;
                border: 0 !important;
                box-shadow: none !important;
            }

            .content.tracking-by-number {
                align-self: flex-start;
            }
        }
    }

    @media only screen and (max-width: 505px) {
        #page-enroll {
            width: 90% !important;
            overflow: hidden;

            .content {

                .button1,
                .button2 {
                    width: 140px;
                }
            }

            .content.sign-up-content .text-inputs {
                overflow: hidden;

                .phone {

                    .add-icon,
                    .remove-icon {
                        transform: translateY(5px);
                        position: absolute;
                        right: 12px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 380px) {
        .content {

            .button1,
            .button2 {
                width: 119px;
            }
        }

        .sign-out .tracking-form .sign-in-search {
            width: 240px;
        }
    }
}