#page-user-info {
    .page-content {
        width: 638px;
    }

    .page-main-title {
        width: 100%;
        margin: 40px 0 0 0;
    }

    h2 {
        margin: 0 5px 0 0;
    }

    .main-title {
        margin: 25px 0 15px 0;
        font-size: 20px;
        font-weight: 500;
        color: var(--dark5);
    }

    .page-content-main {
        margin: auto 0;
        padding: 55px 0;
        width: 100%;

        .content {
            min-height: 50vh;
        }
    }

    .content {
        width: 100%;
        border-radius: 12px;
        border: 1px solid var(--dark2);
        padding: 20px 30px 30px 30px;
        background-color: var(--light3);
        box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);

        .field {
            border-top: 2px solid var(--dark3-8);
            width: 100%;
        }

        .field:last-of-type {
            margin-top: 20px;
        }

        .title {
            margin-left: 15px;
            padding: 0 5px;
            width: fit-content;
            background-color: var(--light3);
            color: var(--dark6-7) !important;
            font-size: 15px;
            z-index: 1;

            span {
                margin: 0 2px;
                color: var(--warning);
            }
        }

        .row {
            width: 100%;

            div:last-of-type {
                margin-right: 0;
            }

            .account-field .title {
                width: auto;
            }
        }

        .account-field {
            border-top: none;

            input {
                width: auto;
            }
        }

        /* Inputs */
        input {
            margin-top: -11.11px;
            margin-bottom: 18px;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 10px 25px;
            height: 20px;
            width: calc(100% - 50px);
            font-size: 16px;
            text-align: center;
            background-color: var(--light3);
        }

        input::placeholder {
            opacity: 0.6;
        }

        input:focus {
            outline-color: var(--sub1);
        }

        input:disabled {
            color: var(--dark6-5);
        }

        .input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        /* Dropdown */
        .dropdown {
            width: 100% !important;
            position: relative;


            a {
                color: var(--dark6);
            }

            svg {
                font-size: 16px;
                transform: none;
            }

            svg:hover {
                color: var(--dark6-7);
            }
        }

        .dropdown-text {
            margin-top: -11.11px;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 10px 25px;
            height: 20px;
            font-size: 16px;
            background-color: var(--light3);

            a {
                margin: 0;
                padding: 0;
                width: 100%;
                text-align: center;
                cursor: pointer;
            }
        }

        .dropdown-content {
            display: none !important;
            position: absolute;
            margin-top: 29px;
            border: 1px solid var(--dark2);
            border-radius: 0 0 5px 5px;
            padding: 5px 0;
            background-color: var(--light3);
            z-index: 2;
            width: calc(100% - 1.5px);

            a {
                margin: 0 5px;
                padding: 5px 20px;
                cursor: pointer;
            }

            a:hover {
                color: var(--light1);
                background-color: var(--sub1);
            }
        }

        .dropdown-content.active {
            display: flex !important;
        }

        /* Gender */
        .gender {
            width: calc(50% - 10px);

            .dropdown-content {
                margin: 110px 0 0 -25.5px;

                a {
                    padding-left: 0;
                    width: auto;
                }
            }
        }

        /* DOB */
        .dob {
            width: calc(50% - 10px);

            span {
                margin: 0 4px;
            }
        }

        /* Email */
        .email {
            width: 100% !important;
        }

        /* Phones */
        .phones {
            width: 100% !important;

            .line-sub-wrap {
                width: 29%;
            }

            .dropdown-text {
                width: auto;
            }

            .dropdown-content {
                margin-top: 25.5px;
                width: calc(100% - 1.5px);
            }

            .phone-line {
                margin-bottom: 15px;
            }

            .phone-item {
                width: 100% !important;
            }

            .title {
                height: 22.5px;
            }

            div {
                width: 100%;
            }

            .phone-country-code {
                width: auto;
            }

            .phone-input-wrap {
                margin-left: 20px;
                width: 69%;
            }

            input {
                text-align: left;
                margin-bottom: 0;
                padding: 12px 25px 8px 25px;
            }

            input:disabled {
                text-align: right;
                border-radius: 5px 0 0 5px;
                padding: 12px 20px 8px 20px;
                width: 50px;
            }

            input.phone-number {
                border-left: none;
                border-radius: 0 5px 5px 0;
            }

            .phone-type {
                width: 70px;
                margin-right: 20px;
            }

            .icon {
                margin-left: 12px;
            }

            .icon-add,
            .icon-remove {
                margin-top: 14px;
            }
        }

        /* Address */
        .address {
            margin-top: 15px !important;
            width: 100% !important;

            .address-content {
                width: 100% !important;
            }

            .column {
                width: 30% !important;
            }

            .dropdown-text {
                margin-right: 0;
                width: auto;
            }

            .dropdown-content {
                margin-top: 48px;
                max-height: 250px;
                overflow-y: scroll;

                a {
                    transform: translateX(-3px);
                    text-align: center;
                }
            }

            .dropdown-content#dropdown-city-content {
                width: calc(100% - 1px);
            }

            div {
                .input-zipcode {
                    margin-bottom: 0;
                }
            }

            .address-detail {
                width: 100%;
            }

            .input {
                margin-right: 7px;
            }

            .address-zipcode {
                margin-right: 10px;
            }
        }

        /* Captcha */
        .captcha {
            width: 100%;
            margin: 37px 0 10px 0;
        }

        /* Button */
        .button1 {
            margin-top: 15px;
            background-color: var(--sub1);
            border: 2px solid var(--sub1);
            border-radius: 0;
            padding: 3px 0;
            width: 80px;
            color: var(--light1);
        }

        .button1:hover {
            background-color: var(--light3);
            color: var(--sub1);
        }

        .submit {
            padding: 8px 0;
            font-size: 14px;
            width: 170px;
            align-self: center;
        }

        .cancel {
            margin-top: 8px;
            font-size: 13px;
            text-decoration: underline;
            cursor: pointer;
        }

        .cancel:hover {
            font-weight: bold;
        }

        /* Error Message */
        .error-msg {
            text-align: center;
            color: var(--warning);
            margin-top: 10px;
            font-size: 14px;
        }

        /* Icons */
        svg {
            font-size: 20px;
            color: var(--dark6-7);
            cursor: pointer;
        }

        svg:hover {
            color: var(--sub5);
        }

        /* Password */
        .password {
            width: 100% !important;

            div {
                width: 100%;
            }

            input {
                margin-bottom: -4px;
            }

            .password-content {
                margin: 0;
            }

            .password-input {
                margin-right: 20px;
            }

            .old-password input {
                margin-right: 0;
            }

            .new-password {
                margin-bottom: 0;
            }

            svg {
                align-self: flex-end;
                font-size: 22px;
                transform: translate(-13px, -28px);
            }
        }

        .password-msg {
            margin-left: 53px;
            font-size: 14px;
            color: var(--warning);
        }

        /* Invalid input */
        .invalid-msg {
            margin: 3px 0 5px 0;
            height: 12px;
            font-size: 12px !important;
            color: var(--warning) !important;
            background-color: transparent !important;
            transform: translateY(-8px);
        }

        /* Invalid input */
        .invalid-input {
            border: 2px solid var(--warning) !important;
            animation: shake 0.6s linear forwards;
        }
    }

    // Main icon
    .main-icon {
        box-shadow: 0 0 15px var(--shadow);
        background-color: var(--light3);
        transform: translateY(-35px);
        border: 1px solid var(--dark2);
        border-radius: 50%;
        padding: 15px;
        font-size: 40px;
        color: var(--dark6-7);
        position: absolute;
        z-index: 2;
    }

    @media only screen and (max-width: 925px) {
        background-color: var(--light3);

        .page-content {
            max-width: calc(90vw);
        }

        .page-content-main {
            padding: 0;
        }

        .page-main-title {
            margin: 0;
        }

        .content {
            border: 0;
            padding: 30px !important;
            box-shadow: none;
        }

        .main-icon {
            display: none;
        }
    }

    @media only screen and (max-width: 600px) {
        .page-content-main {
            width: 90%;
        }

        .content {
            .row {
                flex-direction: column;
            }

            .field {
                margin-top: -10px;
            }

            .main-title {
                margin: 20px 0 10px 0;
            }

            .password {
                .new-password {
                    flex-direction: column;

                    .password-input {
                        margin-right: 0;
                        margin-bottom: 18px;
                    }
                }
            }

            .gender {
                width: 100%;
            }

            .dob {
                margin-top: 18px;
                width: 100%;
            }

            .phones {
                .line-div {
                    margin-bottom: 7.5px;
                }

                .phone-line {
                    margin-bottom: 7.5px;
                }

                .phone-input {
                    margin-top: 7.5px;
                }

                .phone-input-wrap {
                    margin-left: 0;
                    width: 100%;
                }

                .line-sub-div {
                    flex-direction: column;
                    width: 100%;
                }

                .line-sub-wrap {
                    margin-bottom: 8px;
                    width: 100%;
                }

                input {
                    width: calc(100% - 50px);
                }

                input:disabled {
                    width: 50px;
                }

                .phone-item.edit {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .icon-add, .icon-remove {
                    transform: translateY(39px);
                    position: absolute;
                    right: 8px;
                }
            }

            .address {
                margin-top: 0 !important;

                .address-detail {
                    flex-direction: column;
                }

                .column {
                    width: 100% !important;
                }

                .dropdown-text {
                    margin-bottom: 18px;
                }
            }
        }
    }

    @media only screen and (max-width: 435px) {
        .content {

            .button1,
            .button2 {
                width: 115px;
            }
        }
    }
}