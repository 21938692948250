.loader {
  border: 8px solid var(--dark3-8);
  border-top: 8px solid var(--sub1);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}