#page-qa {
	.page-content {
		width: 100vw;
		padding: 15px 0 40px 0;
		background-color: var(--light3);

		.content {
			max-width: 1440px;
			width: calc(100% - 120px);

			h2 { text-align: center; }
		}
	}

	/* Toggle Q&A */
	.toggle-menu {
		width: 100%;
		margin-bottom: 15px;

		a {
			margin-left: 15px;
			cursor: pointer;
		}

		a:hover {
			color: var(--sub1);
		}
	}

	/* List */
	.list-container {
		width: calc(100% - 40px);
		background-color: var(--light3-8);
		margin-bottom: 20px;
		padding: 15px 20px;
		border: 1px solid var(--dark2);
		box-shadow: 0 0 2px var(--shadow);

		svg {
			font-size: 25px;
			color: var(--dark6-7);
			cursor: pointer;
		}

		svg:hover {
			color: var(--dark1);
		}

		.list-title {
			color: var(--dark6);
		}
	}

	.list-container:hover { background-color: var(--light5); }

	.list-content {
		margin-top: 0;
		height: 0;
		clip-path: inset(0 0 100% 0);
		transition: all 0.5s ease, opacity 1s ease;
	}

	.list-content.show {
		margin-top: 10px;
		height: auto;
		clip-path: inset(0 0 0 0);
	}

	.list-table { margin-top: 3px; }

	.list-table-element {
		margin-bottom: -1.5px;

		.list-table-column {
			margin-right: -1px;
			border: 1px solid var(--dark2);
			border-top: none;
			padding: 7px 15px;
			width: 10%;
			min-width: 50px;
			text-align: center;
		}

		div {
			width: 90% !important;
			text-align: left !important;

			a {
				margin-top: 2px;
			}
		}
	}

	.list-table-element:first-of-type .list-table-column { border-top: 1px solid var(--dark2); }

	.list_table_content_list a:first-of-type { margin-right: 5px; }

	.list-list a { margin-top: 2px; }

	.list-table-content-list a:first-of-type,
	.list-list-container a:first-of-type { margin-right: 5px; }

	@media only screen and (max-width: 925px) {
		.page-content { 
			padding: 30px 0;
			overflow-y: hidden;
		}
		h2 { margin-top: 0; }
	}

	@media only screen and (max-width: 580px) {
		.page-content .content { width: calc(100vw - 40px); }
	}

	@media only screen and (max-width: 425px) {
		.page-content .list-table-column {
			display: flex;
			align-items: center;
		}
	}
}