#order-system {
    min-height: calc(100vh - 300px);

    /* Order list */
    .order-list {
        margin-top: 20px;
        border: 1px solid var(--dark5);
        min-width: 520px;

        .title a {
            font-weight: bold;
            background-color: var(--dark5-1);
        }

        .title:hover {
            background-color: var(--dark5-1);
        }

        .order-list-content div:nth-of-type(2n) {
            background-color: var(--blue1);
        }

        div {
            a {
                border: 1px solid var(--dark5);
                padding: 5px 10px;
                text-align: center;
            }

            a:first-of-type {
                width: 125px;
            }

            a:nth-of-type(2) {
                width: 92px;
            }

            a:nth-of-type(3) {
                width: 67px;
            }

            a:nth-of-type(4) {
                width: 100px;
            }

            a:last-of-type {
                width: 136px;
            }
        }

        div.no-record {
            border: 1px solid var(--dark5);
            padding: 7px 0;
        }

        .order-list-content {
            div {
                cursor: pointer;

                a span.canceled {
                    font-weight: 500;
                    color: var(--warning);
                }
            }

            div:hover {
                background-color: var(--sub3);
            }
        }
    }
}