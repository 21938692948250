@keyframes text {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes text2 {
    from {
        opacity: 0;
        transform: translateX(-30px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes map {
    from {
        opacity: 0;
        transform: translateX(-150px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-left {
    from {
        opacity: 0;
        transform: translateX(500px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-up {
    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down1 {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down2 {
    from {
        opacity: 0;
        width: 0;
        height: 0;
        transform: translate(70vw, -100vh);
    }

    to {
        opacity: 1;
        width: 600px;
        height: 700px;
        transform: translate(0, 0);
    }
}

@keyframes slide-down3 {
    from {
        opacity: 0;
        transform: translateY(-300px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-left {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes bounce {
    0% {
        opacity: 1;
        transform: translateY(-20px);
    }

    50% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 1;
        transform: translateY(-20px);
    }
}

@keyframes scale {
    from {
        transform: scale(0.16);
    }

    to {
        transform: scale(0.22);
    }
}

@keyframes shake {
    0% {
        transform: translateX(-15px);
    }

    25% {
        transform: translateX(15px);
    }

    50% {
        transform: translateX(-15px);
    }

    75% {
        transform: translateX(15px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes tilt {
    0% {
        transform: rotate(-25deg);
    }

    25% {
        transform: rotate(25deg);
    }

    50% {
        transform: rotate(-25deg);
    }

    75% {
        transform: rotate(25deg);
    }

    100% {
        transform: rotate(0);
    }
}

@keyframes hover-button {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.8;
    }
}

@keyframes horizontalMove {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(50px);
    }
}