#page-management-content {
    margin-top: 107px;
    background-color: var(--light3);

    .page-content {
        margin: 40px 0 60px 0;
    }

    // Side bar
    .side-bar {
        position: fixed;
        left: 0;
        margin-top: -40px;
        border: 1px solid var(--dark5);
        padding: 30px 0;
        height: 100vw;
        background-color: var(--dark5-1);

        a {
            margin: 3px 0;
            padding: 8px 65px 8px 55px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
        }

        a.current {
            background-color: var(--dark5-3);
        }

        a:hover {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}