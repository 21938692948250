#page-reset {
    background-color: var(--light1);

    .content {
        max-width: 586px;
        width: 100%;
        height: 100%;
        margin: 40px 0;
        border-radius: 12px;
        border: 1px solid var(--dark2);
        padding: 15px 10% 20px 10% !important;
        padding: 0;
        box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);

        h2 {
            text-align: center;
        }
    }

    .text-inputs {
        margin: 5px 0;

        div:last-of-type {
            margin-top: -20px;
        }

        a {
            margin-left: 15px;
            padding: 0 5px;
            background-color: var(--light2);
            color: var(--dark6-7);
            font-size: 15px;
            z-index: 1;
        }

        span {
            margin: 0 2px;
            color: var(--warning);
        }

        input {
            margin-top: -11.11px;
            margin-bottom: 18px;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 10px 25px;
            max-width: calc(80vw - 50px);
            width: 350px;
            height: 20px;
            font-size: 16px;
            background-color: var(--light2);
        }

        input:focus {
            outline-color: var(--sub1);
        }

        svg {
            align-self: flex-end;
            font-size: 20px;
            color: var(--dark6-7);
            cursor: pointer;
            transform: translate(-10px, -50px);
        }

        svg:hover {
            color: var(--sub1);
        }
    }

    .button1 {
        align-self: center;
        margin: 20px 0;
        border-width: 3px !important;
        border-radius: 10px;
        padding: 8px 0;
        width: 170px;
        font-size: 14px;
    }

    .invalid-input {
        border-color: var(--warning) !important;
        border-width: 2px !important;
        animation: shake 0.6s linear forwards;
    }

    .error-msg {
        color: var(--warning);
        margin: -5px 0 15px 0;
        font-size: 12px;
        max-width: 80vw;
        width: 400px;
        text-align: center;
    }

    @media only screen and (max-width: 620px) {
        .page-content {
            align-items: flex-start;

            .content {
                margin: 0;
                border-radius: 0;
                border: 0;
                padding: 30px 0;
                width: 100%;
                box-shadow: none !important;
            }
        }
    }
}