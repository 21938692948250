html {
  font-family: sans-serif, Arial, "Microsoft YaHei", 黑體, 宋體, 微軟雅黑;
}

html:after {
  content: "";
  background: rgba(0, 0, 0, 0.50) url(../files/images/background.jpeg);
  background-blend-mode: darken;
  background-repeat: repeat-y;
  background-size: cover;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -2;
}

body {
  margin: 0;
  color: var(--dark5);
}

.page {
  margin-top: 108px;
  overflow-x: hidden;
}

.page-content {
  min-height: calc(100vh - 306px);
}

.w-100 {
  width: 100%;
}

svg {
  overflow: visible;
}

/* Flexbox */
.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.h-center {
  justify-content: center;
}

.h-between {
  justify-content: space-between;
}

.h-end {
  justify-content: flex-end;
}

.h-around {
  justify-content: space-around;
}

.v-start {
  align-items: flex-start;
}

.v-center {
  align-items: center;
}

.v-baseline {
  align-items: baseline;
}

.v-end {
  align-items: flex-end;
}

.v-self-center {
  align-self: center;
}

/* Scroll Bar */
::-webkit-scrollbar {
  height: 12px;
  width: 14px;
}

::-webkit-scrollbar-track {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark2);
  border-radius: 6px;
  width: 10px;
  border-left: 2px solid var(--light2);
  border-right: 2px solid var(--light2);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark6-7);
}

/* Buttons */
.button1 {
  background-color: var(--sub1);
  border: 2px solid var(--sub1);
  border-radius: 25px;
  padding: 15px 0;
  color: var(--light1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.button1:hover {
  background-color: var(--light1);
  border: 2px solid var(--sub1);
  color: var(--sub1);
  font-weight: 500;
}

.button2 {
  width: 140px;
  padding: 5px 0;
  border: 2px solid var(--sub4);
  border-radius: 40px;
  background-color: var(--sub4);
  box-shadow: 3px 3px 10px var(--shadow);
  cursor: pointer;
  transition: all 0.3s ease;
}

.button2:hover {
  color: var(--sub4);
  background-color: var(--light2);
}

/* List menu */
.list-menu {
  width: 100vw;
  padding: 20px 0;
  background-color: var(--dark4);
  overflow-x: scroll;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */

  a {
    text-wrap: nowrap;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
  }

  .divider {
    width: 1px;
    height: 16px;
    margin: 0 15px;
    border-right: 2px solid var(--dark5);
    cursor: default;
  }

  .menu-content {
    margin: 0 auto;
    padding: 0 20px;
    width: 215px;
  }

  .menu-active {
    color: var(--dark1);
    font-weight: bold;
  }
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.list-menu::-webkit-scrollbar {
  display: none;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark6-7);
  z-index: 3;
}

.modal-content {
  width: 600px;
  height: 60%;
  background-color: var(--light2);
  box-shadow: 5px 5px 10px var(--dark5-6);
  overflow-y: scroll;
  padding-bottom: 20px;
  z-index: 4;
}

/* Responsive */
@media only screen and (min-width: 926px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 925px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block;
  }

  .page {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 580px) {
  .page {
    margin-top: 100px;
  }
}

/* Color code & some dims */
:root {
  --main: #387A2D;
  --main-9: #387A2D90;
  --main-7: #387A2D70;
  --main-5: #387A2D50;
  --sub1: #4CBB17;
  --sub1-8: #4CBB1780;
  --sub1-3: #4CBB1730;
  --sub2: #86CC6D;
  --sub3: #E4F0E0;
  --sub4: #A5C882;
  --sub4-9: #A5C88290;
  --sub4-6: #A5C88260;
  --sub4-4: #A5C88240;
  --sub5: #AFCC6B;
  --sub6: #83A165;
  --sub7: #C6E6C7;
  --sub8: #4CAF50;
  --sub9: #4E7D20;
  --dark1: #202835;
  --dark2: #C4C4C4;
  --dark2-9: #C4C4C490;
  --dark3: #DEDEDE;
  --dark3-8: #DEDEDE80;
  --dark4: #F2F2F2;
  --dark4-8: #F2F2F280;
  --dark5: #5E5E5E;
  --dark5-8: #5E5E5E80;
  --dark5-6: #5E5E5E60;
  --dark5-3: #5E5E5E30;
  --dark5-1: #5E5E5E10;
  --dark6: #000000;
  --dark6-9: #00000090;
  --dark6-99: #00000099;
  --dark6-7: #00000070;
  --dark6-5: #00000050;
  --dark7: #D9D9D9;
  --dark8: #F6F7FE;
  --dark9: #929396;
  --dark10: #A4DED7;
  --dark11: #BCBCBC;
  --dark11-8: #BCBCBC80;
  --dark11-4: #BCBCBC40;
  --dark11-2: #BCBCBC20;
  --dark12: #E7E7E7;
  --dark13: #AFAFAF;
  --dark14: #EFEFEF;
  --shadow: #5E5E5E30;
  --shadow1: #5E5E5E70;
  --light1: #FAFAFA;
  --light2: #FBFBFB;
  --light3: #FFFFFF;
  --light3-8: #FFFFFF80;
  --light3-5: #FFFFFF50;
  --light4: #F3F5F9;
  --light5: #F6F8FA;
  --light6: #F6F1ED;
  --blue1: #F9FAFB;
  --yellow: #F3B42C;
  --red: #FF5353;
  --red-2: #FF535320;
  --red1: #F95151;
  --warning: #EC5757;
  --facebook: #006AFF;
  --facebook1: #0084FF;
  --instagram: #E1306C;
  --line: #00B900;
  --google: #CF4332;
}