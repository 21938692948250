#management-add-product {
    &.modal {
        top: 30px;
    }

    .modal-content {
        height: calc(85vh - 40px);
        padding: 20px 60px;
        position: relative;

        h2 {
            margin: 0 0 20px 0;
            text-align: center;
        }

        .row {
            margin-bottom: 10px;
        }

        .column {
            width: 48%;
        }

        .title {
            margin-left: 15px;
            padding: 0 5px;
            background-color: var(--light2);
            color: var(--dark6-7);
            font-size: 15px;
            z-index: 1;
        }

        /* Inputs */
        input {
            width: calc(100% - 50px);
            margin-top: -11.11px;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 10px 25px;
            height: 20px;
            font-size: 16px;

            &::placeholder {
                opacity: 0.4;
            }

            &:focus {
                outline-color: var(--sub1);
            }
        }

        .image-inputs {
            width: calc(100% - 50px);
            margin-top: -11.11px;
            border: 1px solid var(--dark5);
            border-radius: 5px;
            padding: 10px 25px;
        }

        .image-input-container {
            margin-top: 5px;
            padding: 5px 10px 10px 10px;
            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--facebook);
            }

            img {
                margin-left: 20px;
                height: 150px;
                max-width: 300px;
                object-fit: contain;
            }

            .delete {
                padding-left: 10px;
                color: var(--red);
                cursor: pointer;

                &:hover {
                    color: var(--google);
                }
            }
        }

        input[type="file"] {
            width: 105px;
            margin-top: -3px;
            border: none;
            border-radius: 0;
            padding: 0;
            height: 30px;
            line-height: 34px;
            cursor: pointer;
            color: transparent;
        }

        .add-more-item {
            margin-top: 10px;
            font-size: 14px;
            color: blue;
            align-self: center;
            cursor: pointer;

            span {
                color: blue;
                text-decoration: underline;
                text-underline-offset: 3px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        span {
            margin: 0 2px;
            color: var(--warning);
        }

        .two-inputs {
            input:first-of-type {
                padding: 10px 20px;
                border-radius: 5px 0 0 5px;
                border-right: 0;
                background-color: var(--light3);
                font-size: 14px;
                text-align: center;
            }

            input:last-of-type {
                border-radius: 0 5px 5px 0;
            }
        }

        .one-input,
        .one-input .two-inputs {
            width: 100%;
        }

        .price {
            input:first-of-type {
                width: 30px;
            }
        }

        .shorts {
            div {
                margin-bottom: 20px;

                input:first-of-type {
                    width: 14px;
                }

                svg {
                    margin-left: 10px;
                    font-size: 20px;
                    color: var(--dark6-7);
                }
            }
        }

        .other-pics {
            .add-more-item {
                margin-top: 15px;
                align-self: center;
            }
        }

        /* Dropdowns */
        .dropdown-text {
            width: calc(100% - 50px);
            margin-top: -11.11px;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 10px 25px;
            height: 20px;
            font-size: 16px;
            background-color: var(--light3);
            cursor: pointer;

            a {
                width: 100%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                svg {
                    color: var(--sub1);
                }
            }

            svg:hover {
                color: var(--sub1);
            }
        }

        .dropdown {
            svg {
                font-size: 16px;
                color: var(--dark6-7);
            }

            .dropdown-content {
                display: none;
                flex-direction: column;
                position: absolute;
                width: 586.5px;
                margin-top: 50px;
                border: 1px solid var(--dark2);
                border-radius: 0 0 5px 5px;
                padding: 5px 0;
                background-color: var(--light3);
                z-index: 2;

                .main-type {
                    margin: 3px 10px;
                    color: var(--dark9);
                    text-decoration: underline;
                }

                .option {
                    margin: 0 10px;
                    padding: 5px 10px;
                    cursor: pointer;

                    &:hover,
                    svg:hover {
                        background-color: var(--sub3);
                    }
                }
            }
        }

        /* Colors and Deals sections */
        .colors,
        .deals {

            .delete,
            .delete:hover {
                color: var(--red);

                &:hover {
                    opacity: 0.7;
                }
            }

            input[type="text"] {
                margin: 0 5px;
                border-radius: 0;
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 3px 10px;
                background-color: transparent;

                &:focus {
                    outline: none;
                }

                &.text-align-left {
                    text-align: left;
                }
            }

            input[type="color"] {
                margin: 0 0 0 5px;
                border-radius: 0;
                padding: 3px 5px;
                width: 80px;
            }

            .new-color {
                cursor: auto;

                svg,
                input[type="color"] {
                    cursor: pointer;
                }
            }

            button {
                margin-left: 5px;
                border: 1px solid var(--main);
                border-radius: 3px;
                padding: 2px 0;
                color: var(--main);
                background-color: var(--sub3);
                min-width: 45px;
                cursor: pointer;
            }
        }

        .color-menu {
            flex-wrap: wrap;

            a {
                margin: 0 0 5px 20px;
                padding: 3px 15px;
                color: var(--main);
                border: 1px solid var(--main);
                border-radius: 3px;
                background-color: var(--sub3);
                cursor: pointer;
            }
        }

        .product-container {
            margin: 10px 0 15px 0;
            border: 1px solid var(--dark5);
            border-radius: 5px;
            padding: 11px 20px 15px 20px;
            background-color: var(--dark4);

            .title,
            input {
                background-color: var(--dark4) !important;
            }

            input {
                border-color: var(--dark5);
            }

            /* Size */
            .size {
                margin-bottom: 30px;

                .title-block {
                    margin-bottom: 2px;

                    .control {
                        margin: 0 0 0 110px;
                    }
                }

                .content {
                    flex-wrap: wrap;
                    margin-left: 15px;

                    .size-info-wrap {
                        margin: 10px 0 0 0;
                    }

                    .size-info {
                        width: 80px;
                        height: 35px;
                        border: 1px solid var(--dark3);
                        border-radius: 5px;
                        background-color: var(--light3);
                        font-size: 18px;
                    }

                    .size-info-input {
                        width: 61px;
                        height: 15px;
                        margin-top: 0;
                        border: 1px solid var(--dark5);
                        border-radius: 5px;
                        padding: 10px;
                        background-color: var(--dark11) !important;
                        color: var(--dark1);
                        font-size: 18px;
                        text-align: center;
                    }

                    .size-info-input::placeholder {
                        color: var(--dark1);
                        font-size: 15px;
                    }

                    .times {
                        margin: 0 10px;
                    }

                    .quantity {
                        margin: 0 10px 0 0;
                        border: 1px solid var(--dark3);
                        padding: 7.5px 10px;
                        background-color: var(--light3) !important;
                        width: 100px;
                        text-align: center;
                    }

                    .delete {
                        font-size: 20px;
                        cursor: pointer;
                    }

                    .delete:hover {
                        color: var(--red1);
                    }

                    .no-sizes {
                        margin-top: 8px;
                        width: 238.5px;
                        opacity: 0.8;
                    }
                }

                .control {
                    margin-top: 10px;
                    color: blue;
                    font-size: 15px;

                    a {
                        cursor: pointer;
                    }

                    a:first-of-type {
                        margin: 0 11px 0 35px;
                    }

                    a:last-of-type {
                        margin-left: 16px;
                    }

                    a:hover span {
                        text-decoration: underline;
                    }

                    span {
                        margin-left: 5px;
                        color: blue;
                    }
                }

                .control.bottom {
                    width: 238.5px;
                }
            }
        }

        /* Add, Update, Delete product buttons */
        .button-wrapper {
            margin: 35px 0 30px 0;
        }

        .button1 {
            background-color: var(--light1);
            padding: 10px 0;
            border-radius: 13px;
            font-size: 16px;
            width: 130px;

            &:hover {
                background-color: transparent;
            }
        }

        .add-product {
            align-self: center;
            border-color: var(--dark6);
            background-color: var(--dark6);

            &:hover {
                color: var(--dark6);
            }
        }

        .delete-product {
            margin-left: 20px;
            border-color: var(--red1);
            background-color: var(--red1);

            &:hover {
                color: var(--red1);
            }

            svg {
                margin-left: 2px;
                font-size: 17px;
            }
        }

        /* Specs sections */
        .specs {
            margin: -35px 0 20px 0;

            .added-spec-title {
                margin-top: 8px;

                a {
                    border: 1px solid var(--dark2);
                    border-bottom: 0;
                    padding: 10px 25px;
                    background-color: var(--dark4);
                    font-weight: bold;

                    &:first-of-type {
                        border-radius: 5px 0 0 0;
                    }

                    &:last-of-type {
                        border-radius: 0 5px 0 0;
                    }
                }

                a:first-of-type {
                    border-radius: 5px 0 0 0;
                }

                a:last-of-type {
                    border-radius: 0 5px 0 0
                }

                a:first-of-type {
                    width: 48.336px;
                }

                a:nth-of-type(2) {
                    border-left: 0;
                    border-right: 0;
                    width: 176.836px;
                }

                a:last-of-type {
                    width: 176.836px;
                }
            }

            .added-spec {
                input {
                    margin-top: 0;
                    border: 1px solid var(--dark2);
                    border-bottom: 0;
                    border-radius: 0;
                    padding: 10px 25px;
                    background-color: var(--light3);
                    height: 18.75px;

                    &:first-of-type,
                    &:last-of-type {
                        width: 176.836px;
                    }

                    &:first-of-type {
                        width: 48.336px;
                    }
                }

                input:first-of-type {
                    width: 48.336px;
                }

                input:nth-of-type(2) {
                    border-left: 0;
                    border-right: 0;
                    width: 176.836px;
                }

                input:last-of-type {
                    width: 176.836px;
                }
            }

            .spec-row {
                margin-top: 18px;
            }

            .delete,
            .add {
                margin-left: 10px;
                color: var(--red);
                font-size: 20px;
                cursor: pointer;

                &.add {
                    color: var(--sub1);
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            .new-spec-row input {
                margin: 0;

                &:first-of-type {
                    width: 25%;
                    border-radius: 0 0 0 5px;
                }

                &:nth-of-type(2) {
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0;
                }

                &:last-of-type {
                    border-radius: 0 0 5px 0;
                }
            }
        }

        /* Invalid input handling */
        .invalid-msg {
            margin: 5px 0 0 0;
            height: 12px;
            font-size: 12px !important;
            color: var(--warning) !important;
        }

        .invalid-input {
            border-color: var(--warning) !important;
            border-width: 2px !important;
            animation: shake 0.6s linear forwards;

            &:focus {
                outline: none;
            }
        }

        .any-invalid {
            margin: 20px 0 -10px 0;
            font-size: 15px !important;
        }
    }

    /* Popup for success and deletion */
    .success-pop-up {
        height: 100px;
        width: 400px;

        button {
            margin-top: 15px;
        }
    }

    .delete-pop-up {
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 5;

        .modal-content {
            border: 2px solid var(--dark5);
            height: 100px !important;
            width: 300px;

            .button-wrapper {
                margin: 13px 0 0 0;

                a {
                    color: var(--red);
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                        text-underline-offset: 3px;
                    }
                }

                button {
                    margin-right: 15px;
                    padding: 2px 9px;
                    border-radius: 0;
                    width: auto;
                    color: var(--sub1);

                    &:hover {
                        background-color: var(--sub1);
                        color: var(--light1);
                    }
                }
            }
        }
    }

    /* Icons */
    .icon {
        font-size: 20px;
        color: var(--dark6-7);
        cursor: pointer;

        &:hover {
            color: var(--sub1) !important;
        }
    }

    .close {
        align-self: flex-end;
        margin-bottom: 10px;
        transform: translateX(35px);

        &:hover {
            color: var(--sub1);
        }
    }
}