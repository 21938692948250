#carousel {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    // Slides
    .carousel-slides {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }

    .carousel-slide {
        min-width: 100%;

        img {
            width: 100%;
        }
    }

    .carousel-slide.active {
        display: block;
    }

    .carousel-slide.link {
        cursor: pointer;
    }

    // Buttons - prev/next
    .carousel-button {
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        color: var(--dark5);
        font-size: 40px;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
    }

    .carousel-button:hover {
        opacity: 0.4;
    }

    .carousel-button.prev {
        left: 20px;
    }

    .carousel-button.next {
        right: 20px;
    }

    // Indicators
    .carousel-indicators {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 5px;
    }

    .indicator {
        margin-left: 3px;
        width: 15px;
        height: 15px;
        background-color: var(--dark6-5);
        border-radius: 50%;
        cursor: pointer;
    }

    .indicator:hover {
        opacity: 0.6;
    }

    .indicator.active {
        background-color: white;
    }

    @media only screen and (max-width: 925px) {
        .carousel-button {
            font-size: 30px;
        }

		.indicator {
            width: 12px;
            height: 12px;
        }
	}
}