#edit-products {
    margin: 0;
    min-height: calc(100vh - 326px);

    /* Modal */
    .modal {
        z-index: 25;
    }

     /* Add new product */
     .add-new-product {
        margin: 0 15px 20px 0;
        border-radius: 30px;
        padding: 10px 25px;
        background-color: var(--sub4-4);
        cursor: pointer;
    }

    .add-new-product:hover a,
    .add-new-product:hover svg {
        opacity: 0.6;
    }

    .add-new-product svg {
        color: var(--main);
    }

    .add-new-product a {
        color: var(--main);
        margin-left: 5px;
    }

    /* Product list */
    .product-list {
        border: 1px solid var(--dark5);

        .title a {
            font-weight: bold;
            background-color: var(--dark5-1);
        }

        .title:hover {
            background-color: var(--dark5-1);
        }

        div:hover {
            background-color: var(--sub3);
        }

        div a {
            width: 100px;
            border: 1px solid var(--dark5);
            padding: 5px 10px;
            text-align: center;
        }

        /* Product name */
        div a:first-of-type {
            width: 160px;
        }

        /* Product model */
        div a:nth-of-type(2) {
            width: 50px;
        }

        /* Product type */
        div a:nth-of-type(6) {
            width: 200px;
        }

        /* Product detail button */
        div a:last-of-type {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }

        div a:last-of-type:hover {
            font-weight: bold;
        }
    }
}