#page-location {
	.page-content {
		padding: 15px 0 75px 0;
		background-color: var(--light3);
		width: 100%;

		.map-container {
			margin-top: 30px;
			width: 1200px;
			transform: translateX(-30px);
			overflow: hidden;
		}
	}

	/* List menu */
	.list-menu { z-index: 2; }

	/* Map - 高雄/台中/新竹/台北 */
	.map {
		margin: -100px 100px 0 0;
		max-height: 800px;
		z-index: 1;
		opacity: 0;
		animation: map 1.3s ease-in forwards;

		path {
			fill: var(--light3);
			stroke: var(--dark6-7);
			filter: drop-shadow(0 0 5px var(--shadow));
		}

		text {
			fill: var(--light1);
			font-size: 20px;
			cursor: pointer;
		}

		image {
			cursor: pointer;
		}

		#TWN1156,
		#TWN1174,
		#TWN1161,
		#TWN1162,
		#TWN1166 {
			fill: var(--sub3);
			cursor: pointer;
		}
	}

	/* Location */
	.location-list {
		padding-right: 200px;
	}

	.location {
		width: 400px;
		margin: 15px 50px 0 0;
		border: 1px solid var(--dark2);
		box-shadow: 0 0 10px var(--shadow);
		background-color: var(--light3);

		iframe {
			margin-bottom: 20px;
		}

		p {
			margin: 0;

			a {
				font-weight: 500 !important;
				text-decoration: none;
				color: var(--dark5);
			}

			a:hover {
				text-decoration: underline;
				color: var(--facebook);
			}

			svg {
				margin: 0 3px 4px 0;
			}
		}

		a {
			margin-bottom: 5px;
		}

		span {
			font-weight: 900;
		}

		svg {
			color: var(--warning);
		}

		button {
			align-self: flex-end;
			margin-top: 5px;
			border: 1px solid var(--dark2);
			padding: 5px 10px;
			background-color: var(--light1);
			box-shadow: 0 0 2px var(--shadow);
			color: var(--dark5);
			cursor: pointer;
		}

		button:hover {
			background-color: var(--dark8);
			color: var(--dark6);
			border: 1px solid var(--dark5);
		}

		.name {
			font-weight: bold;
		}

		.contact {
			width: 73%;
			margin-top: 5px;
		}

		.address svg {
			margin-right: 3px;
		}
	}

	/* Images */
	.img-container {
		flex-wrap: wrap;
		margin-top: 30px;
		width: 80%;
	}

	img {
		opacity: 0;
		margin-bottom: 30px;
		height: 400px;
		animation: slide-down1 0.4s 0.4s ease forwards;
	}

	img:first-of-type {
		animation: slide-down1 0.4s ease forwards;
	}

	@media only screen and (max-width: 1320px) {
		.page-content {
			margin: 0;
			width: 100vw;
		}

		.location-list {
			padding-right: 23vw;
		}

		.location {
			margin-right: 0;
		}
	}

	@media only screen and (max-width: 1000px) {
		.page-content {
			margin: 0;
			width: 100vw;
			padding: 30px 0;

			h2 { margin-top: 0; }
			.map-container { transform: none; }
		}

		.location-list {
			padding: 0;
		}

		.map {
			display: none;
		}
	}

	@media only screen and (max-width: 925px) {
		.page-content {
			padding: 30px 0;

			h2 { margin-top: 0; }
		}
	}

	@media only screen and (max-width: 500px) {
		.location {
			width: calc(90vw - 60px);

			.contact {
				width: 292px;
			}

			iframe {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: 425px) {
		.map-container {
			margin: 25px 0 40px 0;
		}

		.location {
			.contact {
				flex-direction: column;
				align-items: center;
			}

			.address {
				text-align: center;
				width: 250px;
			}

			button {
				align-self: center;
				margin-top: 10px;
			}
		}
	}

	.location:first-of-type {
		margin-top: 0;
	}

	.location:nth-of-type(2) .address {
		justify-content: center;
	}
}