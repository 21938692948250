#page-order-detail {
    .page-content {
        width: 100vw;
        margin: 0;
        padding: 15px 0 40px;
        background-color: var(--light3);

        .content {
            padding: 5px 30px 20px 30px;
            width: 1180px;
        }

        .content-wrap {
            width: 100%;
        }

        .content-left {
            width: 72%;
        }

        .content-right {
            width: 200px;
            height: fit-content;
            padding: 25px;
            background-color: var(--dark4);
        }

        .block {
            margin-bottom: 30px;
            border: 1px solid var(--dark3);
        }

        /* Fields */
        .field-title {
            background-color: var(--dark11-4);
            padding: 5px 20px;

            h3 {
                margin: 0;
                font-size: 16px;
            }
        }

        .field {
            padding: 15px 20px;

            a {
                margin-bottom: 5px;
                overflow-wrap: break-word;

                span.canceled {
                    font-weight: 500;
                    color: var(--warning);
                }
            }

            .title {
                margin-right: 10px;
                font-weight: bold;
            }

            .column {
                margin-bottom: 5px;

                .field-content {
                    margin-left: 15px;
                }
            }
        }

        /* Tracking & Shipping Info */
        .tracking {
            width: 48%;
        }

        .shipping {
            width: 48%;

            .store-content {
                margin: 5px 0 5px 11px;
                padding: 15px 20px;
                background-color: var(--dark4);

                b {
                    margin-right: 5px;
                }
            }
        }

        /* Status */
        .status {
            margin: 10px 0;
            width: 800px;

            text {
                font-weight: bold;
            }
        }

        /* Order detail */
        .order-detail {
            .table {
                display: none !important;
                margin: 0;
                padding: 10px 0 25px 0;
            }

            .table.show {
                display: flex !important;
                border-bottom: 1px solid var(--dark11-8);
            }

            .row {
                padding: 10px 0;

                img {
                    margin-right: 15px;
                    width: 80px;
                    height: 80px;
                    background-color: var(--light3);
                    object-fit: contain;
                }

                a {
                    margin-bottom: 0;
                }

                .name {
                    margin: 0 0 5px 0;
                    font-size: 14px;
                    text-wrap: nowrap;
                    font-weight: 500;

                    a:first-of-type {
                        margin-right: 5px;
                    }

                    a:last-of-type {
                        font-size: 12px;
                    }
                }

                .specs-div {
                    margin-bottom: 5px;
                    color: var(--dark13);
                    font-size: 12px;

                    a:first-of-type {
                        margin-right: 5px;
                    }
                }

                .price-div {
                    width: 100%;

                    .price {
                        margin-right: 5px;
                        font-size: 14px;
                        opacity: 1;
                    }

                    .sale {
                        font-size: 14px;
                        color: var(--red);
                    }
                }
            }

            .item {
                .item-content {
                    width: 100%;
                }

                .info {
                    width: 250px;
                }

                .price {
                    opacity: 0.6;
                    text-decoration: line-through;
                }
            }
        }

        /* Summary */
        .summary-wrap {
            .calculation {
                margin: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid var(--dark2);

                a {
                    padding: 5px 0;
                }

                .discount {
                    color: var(--red);
                }
            }

            .summary {
                padding: 5px 0;
                font-weight: bold;
                font-size: 20px;
            }
        }

        /* Contact Us */
        .contact-us {
            svg {
                margin: 0 3px 0 10px;
                font-size: 20px;
            }

            p {
                margin: 0;
                letter-spacing: 0.8px;
            }

            a {
                margin-left: 0.8px;
                font-weight: bold;
                color: var(--dark5);
                text-decoration: underline;
                cursor: pointer;
            }

            a:visited {
                color: var(--dark5);
            }
        }

        /* No Record */
        .no-record .contact-us {
            margin: 13px 8px 0 0;

            svg {
                margin-left: 0;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .page-content {
            .content {
                width: 90vw;
            }

            .content-wrap {
                flex-direction: column;
            }

            .content-left {
                width: 100%;
            }

            .content-right {
                width: calc(100% - 48px);
                margin: 0 0 50px 0;
            }
        }
    }

    @media only screen and (max-width: 925px) {
        .page-content {
            .content {
                padding: 0;
            }

            .content-wrap {
                margin-top: 5px;
            }

            .content-left {
                align-items: center;
            }

            /* Status bar */
            .status {
                margin-bottom: 12px;
                width: 83vw;

                svg {
                    line {
                        stroke-width: 10;
                    }

                    circle {
                        r: 15px;
                    }

                    text {
                        font-size: 18px;
                        transform: translateY(2px);
                    }
                }
            }

            /* Tracking & Shipping Info */
            .info-wrap {
                flex-direction: column;
                width: 100%;

                .tracking,
                .shipping {
                    width: 100%;
                }
            }

            /* Note */
            .note {
                width: 100%;
            }

            /* Order Detail */
            .order-detail {
                width: 100%;

                .row .name {
                    text-wrap: wrap;
                }

                .item .info {
                    width: auto;
                }
            }

            /* Summary */
            .summary-wrap {
                margin: 0 0 30px 0;
            }
        }
    }
}