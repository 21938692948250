#cookie-usage {
    position: fixed;
    bottom: 0;
    border-top: 1px solid var(--dark2);
    width: calc(100% - 40px);
    padding: 20px;
    background-color: var(--light6);
    text-align: center;
    z-index: 100;
    animation: slide-up 1s ease-in-out forwards;

    button {
        margin: 0 10px 0 15px;
        border-radius: 0;
        padding: 8px 10px;
        border: 2px solid var(--dark6);
        background-color: var(--dark6);
    }

    button:hover {
        color: var(--dark5);
        background-color: transparent;
    }

    a {
        margin-left: 5px;
    }

    @media only screen and (max-width: 600px) {
		p {
            max-width:  50vw;
        }
	}
}