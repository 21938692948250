#pop-up {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 50;

    .content {
        height: auto !important;
        width: 420px !important;
        border: 2px solid var(--dark5);
        padding: 30px 10px !important;
        background-color: var(--light1);
        box-shadow: 5px 5px 10px var(--dark5-6);
        z-index: 60;

        a {
            margin-bottom: 20px;
            font-size: 18px;
        }

        button {
            padding: 8px 0;
            width: 50%;
            margin-bottom: 10px !important;
        }
    }
}

/* Reset password confimation pop up */
#page-reset #pop-up .content {
    padding: 30px 10px !important;
}

/* Sign in send verification pop up */
#page-enroll #pop-up .send-verification {
    width: calc(100% - 50px);

    li {
        a {
            margin: 0 2px;
            width: auto;
            font-size: 16px;
            font-weight: bold;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }

        a.wait {
            color: var(--dark5);
            opacity: 0.5;
            cursor: not-allowed;
        }

        span {
            font-weight: bold;
            opacity: 0.5;
        }
    }
}

/* Redirect to enroll */
#page-enroll #pop-up .redirect-to-enroll {
    margin: 10px 15px 20px 15px;
}

/* Resend verification email pop up */
#page-enroll .resend #pop-up {
    .content {
        width: 600px;
        padding-left: 40px !important;
    }

    ol {
        margin: 10px 0;
    }

    li {
        font-size: 18px;
        line-height: 30px;

        a {
            margin: 0 3px;
            color: var(--facebook);
            cursor: pointer;
        }

        a:hover {
            text-decoration: underline;
        }

    }

    .error-msg {
        margin: -5px 0 0 0;
        display: flex;
    }

    button {
        margin-top: 10px;
    }
}

/* Sign up sucess with social pop up */
#page-enroll .sign-up-social {
    margin-top: 10px;
    padding: 0 33px;
}

/* Sign out pop up */
#sign-out #pop-up button {
    margin: 0 !important;
    border-radius: 10px;
    padding: 10px 0;
}

/* Verify email pop up */
#page-verify-email #pop-up .content {
    width: 600px;
    padding: 40px 20px 35px 40px !important;
}

/* User info pop up */
#page-user-info #pop-up {
    background-color: var(--dark5);
}

/* Management - edit home */
#page-management-content #edit-home #pop-up button {
    width: 130px;
}

/* Management - edit order detail */
#edit-order-detail #pop-up {
    a:first-of-type {
        margin-bottom: 10px;
    }

    .selection {
        width: 300px;

        button {
            margin: 0 10px;
        }
    }

    .update .confirmation {
        display: none;
    }
}