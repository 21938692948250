#management-add-from-file {
  .modal-content {
    height: auto;
    padding: 20px 60px 50px 60px;
    position: relative;

    h2 {
      margin: 0 0 20px 0;
      text-align: center;
    }
  }

  /* Icons */
  .icon {
    font-size: 20px;
    color: var(--dark6-7);
    cursor: pointer;
  }

  .icon:hover {
    color: var(--sub1) !important;
  }

  .close {
    align-self: flex-end;
    margin-bottom: 10px;
    transform: translateX(35px);
  }

  .close:hover {
    color: var(--sub1);
  }

  /* Input */
  input {
    align-self: center;
    margin-top: 20px;
    transform: translateX(40px);
  }

  /* Invalid message */
  .invalid-msg {
    margin: 20px 0 -10px 0;
    font-size: 12px !important;
    color: var(--warning) !important;
    text-align: center;
  }

  /* Add product button */
  .add-product {
    align-self: center;
    margin-top: 40px;
    padding: 10px 0;
    border-radius: 13px;
    font-size: 16px;
    width: 130px;
  }
  .add-product:disabled {
    background-color: var(--dark2);
    border-color: var(--dark2);
    cursor: not-allowed;
  }
  .add-product:disabled:hover {
    color: var(--light1);
  }

  /* Success pop up */
  .success-pop-up {
    height: 120px;
    width: 400px;
    padding: 20px 60px;

    button {
      margin-top: 15px;
      border-color: var(--dark6);
      background-color: var(--dark6);
    }
    button:hover {
      color: var(--dark6);
      background-color: transparent;
    }
  }
}