#promo_banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 38px;
    background-color: var(--dark6);
    z-index: 25;

    p {
        margin: 0;
        color: var(--light3);
        font-size: 14px;
    }
    p:hover span {
        text-decoration: underline;
    }
}