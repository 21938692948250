#page-privacy {
	.page-content {
		width: 100%;
		background-color: var(--light3);
	}

	h2 {
		margin: 20px 0 30px 0;
		align-self: center;
		text-align: center;
	}

	.content {
		max-width: 1440px;
		width: calc(100vw - 120px);
		padding: 15px 60px 80px 60px;

		h3 { margin: 40px 0 -5px 0; }

		h3:first-of-type { margin-top: 10px; }

		a {
			margin: 10px 0 0 12px;
			line-height: 26px;
		}

		li {
			margin-left: 18px;
			line-height: 26px;
		}
	}

	@media only screen and (max-width: 925px) {
		.content { padding: 30px 60px; }
		h2 { margin-top: 0; }
	}

	@media only screen and (max-width: 500px) {
		.content {
			width: calc(100vw - 40px);
			padding: 40px 20px;
		}
		h2 { margin-bottom: 20px; }
		h3 { text-align: center; }
	}
}