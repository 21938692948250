#page-shipping {
    background-color: var(--light3);

    .page-content {
        width: 1280px;
        padding: 15px 60px 100px 60px;

        .content {
            padding: 10px 0 20px 0;
            width: 100%;
        }

        .content-left {
            width: 65%;
        }

        .content-right {
            padding: 15px 30px;
            width: 20%;
            background-color: var(--dark4);
        }

        /* Blocks */
        .block {
            width: calc(100% - 20px);

            .title {
                margin-top: 40px;
            }
        }

        .block:first-of-type .title {
            margin: 0;
        }

        /* Title */
        .title {
            background-color: var(--dark11-4);
            padding: 5px 20px;
            width: calc(100% - 20px);

            a {
                font-weight: bold;
            }

            svg {
                cursor: pointer;
            }

            svg:hover {
                color: var(--dark5-8);
            }

            h3 {
                margin: 0;
                font-size: 16px;
            }
        }

        /* Product list */
        .product-list {
            width: 100%;

            .title {
                border-bottom: 1px solid var(--dark11-8);
                padding: 10px 0;
                background-color: transparent;
                width: 100%;
            }

            .table {
                display: none !important;
                margin: 0;
                padding: 10px 0 25px 0;
            }

            .table.show {
                display: flex !important;
                border-bottom: 1px solid var(--dark11-8);
            }

            .row {
                padding: 10px 0;

                img {
                    margin-right: 15px;
                    width: 80px;
                    height: 80px;
                    background-color: var(--light3);
                    object-fit: contain;
                }

                .name {
                    margin: 0 0 5px 0;
                    font-size: 14px;
                    text-wrap: nowrap;
                    font-weight: 500;

                    a:first-of-type {
                        margin-right: 5px;
                    }

                    a:last-of-type {
                        font-size: 12px;
                    }
                }

                .specs-div {
                    margin-bottom: 5px;
                    color: var(--dark13);
                    font-size: 12px;

                    a:first-of-type {
                        margin-right: 5px;
                    }
                }

                .price-div {
                    width: 100%;

                    .price {
                        margin-right: 5px;
                        font-size: 14px;
                        opacity: 1;
                    }

                    .sale {
                        font-size: 14px;
                        color: var(--red);
                    }
                }
            }

            /* Table items */
            .item {
                .item-content {
                    width: 100%;
                }

                .info {
                    width: 250px;
                }

                .price {
                    opacity: 0.6;
                    text-decoration: line-through;
                }
            }
        }

        /* Inputs */
        .input-row {
            width: calc(100% + 20px);

            div {
                width: 100%;
            }
        }

        .input-row-2column .input-field {
            width: 49%;
        }

        .input-row-3column {
            .input-field {
                width: 31%;
            }

            .dropdown {
                width: calc(100% - 50px);
            }
        }

        .input-field {
            a {
                margin-left: 15px;
                padding: 0 5px;
                background-color: var(--light3);
                color: var(--dark6-7);
                font-size: 15px;
                z-index: 1;
            }

            span {
                margin: 0 2px;
                color: var(--warning);
            }

            textarea {
                margin-top: -11.11px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 15px 25px;
                width: calc(100% - 50px);
                height: 100px;
                font-size: 16px;
                resize: none;
            }

            input {
                margin-top: -11.11px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 10px 25px;
                width: calc(100% - 50px);
                height: 20px;
                font-size: 16px;
            }

            textarea:focus,
            input:focus {
                outline-color: var(--sub1);
            }

            input:disabled {
                color: var(--dark6-5);
                background-color: var(--light3);
            }

            textarea::placeholder {
                color: var(--dark6-7);
            }

            .icon {
                font-size: 20px;
                color: var(--dark6-7);
                cursor: pointer;
            }

            .icon:hover {
                color: var(--sub1);
            }

            /* Dropdown */
            .dropdown {
                position: relative;

                a {
                    color: var(--dark6);
                }

                svg {
                    font-size: 16px;
                    transform: none;
                }
            }

            .dropdown-text {
                margin-top: -11.11px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 10px 25px;
                height: 20px;
                font-size: 16px;

                a {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .dropdown-content {
                display: none !important;
                position: absolute;
                margin-top: 29px;
                border: 1px solid var(--dark2);
                border-radius: 0 0 5px 5px;
                padding: 5px 0;
                z-index: 2;
                background-color: var(--light3);

                a {
                    margin: 0;
                    margin: 0 5px;
                    padding: 5px 20px;
                    cursor: pointer;
                }

                a:hover {
                    color: var(--light1);
                    background-color: var(--sub1);
                }
            }

            .dropdown-content.active {
                display: flex !important;
            }

            #dropdown-type-content {
                width: calc(1280px * 0.65 * 0.29);
            }

            #dropdown-city-content,
            #dropdown-district-content {
                width: calc(1280px * 0.65 * 0.31);
                max-height: 250px;
                overflow-y: scroll;
            }

            /* Gender */
            .gender {
                margin-top: -11.11px;
                width: 100%;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                height: 40px;

                div:first-of-type {
                    margin-right: 23%;
                }

                input {
                    width: 14px;
                    margin: 0 3px 0 5px;
                }
            }

            /* Phone */
            .phone-input {
                width: 100%;
            }

            .phone-input-type {
                width: 29%;
            }

            .phone-input-number {
                width: 69%;

                input:first-of-type {
                    width: 13%;
                    border-radius: 5px 0 0 5px;
                    text-align: center;
                }

                input:last-of-type {
                    width: 87%;
                    border-left: none;
                    border-radius: 0 5px 5px 0;
                }
            }
        }

        /* Company */
        .company {
            margin-top: 20px;
        }

        /* Phone */
        .phone {
            width: calc(100% + 23px);

            .invalid-msg {
                margin-left: calc(29% + 15px);
                margin-bottom: 5px;
            }

            input {
                line-height: 20px;
            }

            input::placeholder {
                font-size: 14px;
                opacity: 0.5;
            }

            .phone-add-remove {
                align-self: center;
                font-size: 23px;
                color: var(--dark6-5);
                transform: translateY(-2px);
            }

            .dropdown {
                width: calc(100% - 50px);
            }
        }

        /* Zipcode */
        .zipcode input {
            text-align: center;
        }

        /* Shipping method */
        .shipping-method {
            .title {
                margin-bottom: 15px;
            }

            .options {
                .option-item {
                    margin: 0 0 5px 12px;
                    border: 1.5px solid transparent;
                    padding: 7px 10px 7px 8px;
                    width: auto;
                    cursor: pointer;
                }

                .option-item.select {
                    border: 1.5px solid var(--dark5) !important;
                }

                .option-item:hover {
                    border: 1.5px solid var(--light4);
                    background-color: var(--light4);
                }

                .option-item:last-of-type {
                    margin-bottom: 0;
                }


                input {
                    margin-right: 8px;
                }

                img {
                    width: 25px;
                    height: 25px;
                    margin-right: 8px;
                }

                .text {
                    width: 90px;
                }

                .text-noimage {
                    width: 123px;
                }

                .price {
                    text-align: center;
                }

                .price.cross-out::after {
                    content: '';
                    position: absolute;
                    width: 74px;
                    height: 1.5px;
                    background: var(--dark5);
                    transform: translate(-71px, 9px);
                }

                .free-shipping {
                    margin-left: 8px;
                    color: var(--red);
                }

                .select-store {
                    opacity: 0.8;
                    margin-left: 10px;
                    border: 1.5px solid var(--dark6);
                    padding: 3px 8px;
                    background-color: var(--dark6);
                    color: var(--light3);
                    font-size: 14px;
                    cursor: pointer;
                }

                .select-store:hover {
                    background-color: var(--light3);
                    color: var(--dark6);
                }
            }

            .invalid-store-selection {
                border: 2px solid var(--warning);
                padding: 10px 12px 10px 0;
                animation: shake 0.6s linear forwards;
                width: fit-content;
            }

            .free-shipping-text {
                margin: 3px 0 0 38px;
                color: var(--facebook);
                font-size: 14px;
            }

            #map-hidden-form {
                display: none;
            }

            .map-address {
                margin: 10px 0 10px 11px;
                padding: 15px 20px;
                background-color: var(--dark4);

                a {
                    line-height: 24px;
                }

                b {
                    margin-right: 5px;
                }
            }

            .invalid-msg {
                margin-left: 12px;
            }
        }

        /* Payment method */
        .payment-method {
            margin: 15px 0 0 12px;

            input {
                margin-right: 8px;
            }
        }

        /* Calculation */
        .calculation {
            margin-bottom: 25px;
            padding: 25px 0;
            border-bottom: 1px solid var(--dark11-8);

            a {
                padding: 5px 0;
                font-size: 14px;
            }

            .discount {
                color: var(--red);
            }
        }

        /* Summary */
        .summary {
            margin-bottom: 25px;
            font-weight: bold;
            font-size: 16px;
        }

        /* Checkout */
        .checkout {
            margin: 15px 0;
            width: 100%;
        }

        /* Error message - recaptcha */
        .error-msg {
            align-self: center;
            color: var(--warning);
            margin: 10px 0 -10px 0;
        }

        /* Place order button */
        .place-order {
            padding: 10px;
            font-size: 16px;
        }

        /* Invalid inputs */
        .invalid-msg {
            margin: 5px 0 8px 0;
            height: 12px;
            font-size: 12px !important;
            color: var(--warning) !important;
        }

        .invalid-input {
            border-color: var(--warning) !important;
            border-width: 2px !important;
            animation: shake 0.6s linear forwards;
        }

        .invalid-input:focus {
            outline: none;
        }
    }

    @media only screen and (max-width: 1400px) {
        .page-content {
            width: 90vw;
            padding: 15px 0 100px 0;

            .content-right {
                width: 22%;
            }

            .product-list .row .name {
                flex-direction: column;
                align-items: flex-start;
                text-wrap: wrap;

                a {
                    line-height: 22px;
                }
            }

            .input-field #dropdown-type-content {
                width: calc(#{90vw} * 0.65 * 0.29);
            }

            .input-field #dropdown-city-content,
            .input-field #dropdown-district-content {
                width: calc(#{90vw} * 0.65 * 0.31);
            }
        }
    }

    @media only screen and (max-width: 975px) {
        .page-content {
            padding-bottom: 60px;

            .content {
                flex-direction: column;
            }

            .content-left {
                width: 100%;
            }

            .content-right {
                margin-top: 40px;
                width: calc(100% - 60px);
            }

            .product-list .row .name {
                flex-direction: row;
                align-items: center;
                text-wrap: nowrap;

                a {
                    line-height: auto;
                }
            }

            .input-field #dropdown-type-content {
                width: calc(#{90vw} * 0.29);
            }

            .input-field #dropdown-city-content,
            .input-field #dropdown-district-content {
                width: calc(#{90vw} * 0.31);
            }

            .mobile-msg-warning {
                display: flex !important;
                margin-top: 15px;
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .page-content {
            .input-row-2column,
            .input-row-3column {
                flex-direction: column;

                .input-field {
                    width: 100%;
                }

                .gender {
                    margin-bottom: 25px;
                }
            }

            .phone-input {
                flex-direction: column;

                .phone-input-type {
                    width: 100%;
                    margin-bottom: 25px;
                }

                .phone-input-number {
                    width: 100%;

                    input:first-of-type {
                        width: 25%;
                        padding: 10px;
                    }
                }
            }

            .input-field #dropdown-type-content,
            .input-field #dropdown-city-content,
            .input-field #dropdown-district-content {
                width: 90vw;
            }

            .dropdown-content a {
                text-align: center;
            }
        }
    }
}