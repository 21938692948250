#page-enroll {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .content {
        width: 600px;
        height: 700px;
        border-radius: 12px;
        padding: 20px 10px;
        box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
        background-color: var(--light3);
        z-index: 4;

        /* Captcha */
        .captcha {
            width: 100%;
            margin: 10px 0;
        }

        /* Close button */
        .close {
            font-size: 24px;
            align-self: flex-end;
            cursor: pointer;
        }

        .close:hover {
            color: var(--sub5);
        }

        /* Buttons */
        button {
            margin-bottom: 20px;
            font-size: 16px;
            cursor: pointer;
        }

        .submit {
            margin-top: 5px;
        }


        /* Divider */
        .divider {
            width: 90%;
            margin: 10px 0 30px 0;
            border-bottom: 2px solid var(--dark2-9);
        }

        /* Input */
        .text-inputs {
            width: 80%;

            a {
                margin-left: 15px;
                padding: 0 5px;
                background-color: var(--light3);
                color: var(--dark6-7);
                font-size: 15px;
                z-index: 1;
            }

            span {
                margin: 0 2px;
                color: var(--warning);
            }

            input {
                margin-top: -11.11px;
                margin-bottom: 15px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 12px 25px 8px 25px;
                width: calc(100% - 50px);
                height: 20px;
                font-size: 16px;
                background-color: var(--light3);
            }

            input:focus {
                outline-color: var(--sub1);
            }

            input:disabled {
                color: var(--dark6-5);
            }

            .icon {
                font-size: 20px;
                color: var(--dark6-7);
                cursor: pointer;
            }

            .icon:hover {
                color: var(--sub5);
            }

            .sign-up-inputs input {
                margin-bottom: 0;
            }

            /* Password */
            .password {
                width: 100%;

                .row { width: 100%; }
                
                input {
                    width: calc(100% - 80px);
                    padding-right: 55px;
                }

                svg {
                    align-self: flex-end;
                    transform: translate(-10px, -47px);
                }
            }

            /* Company */
            .company {
                margin-top: -20px;
            }

            .company input {
                margin-bottom: 20px;
            }

            /* Gender */
            .gender {
                margin-top: -11.11px;
                width: 100%;
                background-color: var(--light3);
                border: 1px solid var(--dark2);
                border-radius: 5px;
                height: 40px;

                div:first-of-type {
                    margin-right: 15%;
                }

                input {
                    width: 14px;
                    margin: 0 3px 0 5px;
                }
            }

            /* DOB */
            input[type="date"]::-webkit-calendar-picker-indicator {
                transform: translateX(15px);
                opacity: 0.5;
                cursor: pointer;
            }

            /* Phone */
            .phone {
                margin-top: -20px;
                width: calc(100% + 23px);

                input {
                    line-height: 20px;
                }

                input::placeholder {
                    font-size: 14px;
                    opacity: 0.5;
                }

                svg {
                    align-self: center;
                    font-size: 23px;
                    color: var(--dark6-5);
                    transform: translate(12px, 9px);
                }
            }

            .phone-input {
                width: calc(100% - 23px);
                margin-top: 20px;
            }

            .phone-input-type {
                width: 29%;
            }

            .phone-input-number {
                width: 69%;

                div { width: 100%; }
                input:first-of-type {
                    width: 40px;
                    border-radius: 5px 0 0 5px;
                    text-align: center;
                }

                input:last-of-type {
                    border-left: none;
                    border-radius: 0 5px 5px 0;
                }
            }

            /* Address */
            .address-detail .column {
                width: 32%;
            }

            /* Dropdown */
            .dropdown {
                width: 100% !important;
                position: relative;

                a {
                    color: var(--dark6);
                }

                svg {
                    font-size: 16px;
                    transform: none;
                }
            }

            .dropdown-text {
                margin-top: -11.11px;
                border: 1px solid var(--dark2);
                border-radius: 5px;
                padding: 10px 25px;
                height: 20px;
                font-size: 16px;
                background-color: var(--light3);

                a {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .dropdown-content {
                display: none !important;
                position: absolute;
                margin-top: 29px;
                border: 1px solid var(--dark2);
                border-radius: 0 0 5px 5px;
                padding: 5px 0;
                background-color: var(--light3);
                z-index: 2;

                a {
                    margin: 0 5px;
                    padding: 5px 20px;
                    cursor: pointer;
                }

                a:hover {
                    color: var(--light1);
                    background-color: var(--sub1);
                }
            }

            .dropdown-content.active {
                display: flex !important;
            }

            #dropdown-type-content {
                width: calc(578px * 0.8 * 0.29);
            }

            #dropdown-city-content,
            #dropdown-district-content {
                width: calc(578px * 0.8 * 0.32);
                max-height: 250px;
                overflow-y: scroll;
            }
        }

        /* Zipcode */
        .zipcode input {
            text-align: center;
        }

        /* Forgot user name/password */
        .forgot { width: 100%; }

        .forgot-pass {
            margin: -23px 0 5px 0;
            text-decoration: underline;
            align-self: center;
            font-size: 12px !important;
            cursor: pointer;
        }

        .forgot-pass:hover {
            font-weight: bold;
        }

        /* Error Message */
        .error-msg {
            color: var(--warning);
            margin-bottom: 10px;
            font-size: 12px;
        }

        /* Button1: 確認, 加入會員 */
        .button1 {
            border-width: 3px !important;
            border-radius: 0;
            padding: 8px 0;
            width: 40%;
            font-size: 18px;
        }

        /* Button2: Social button */
        .social {
            width: 80%;
        }

        .button2 {
            border-width: 3px !important;
            border-radius: 0;
            padding: 10px 0;
            width: 140px;
            transition: all 0.3s;

            svg {
                font-size: 24px;
                margin-right: 5px;
            }
        }

        .button-line {
            background-color: var(--line);
            border: 2px solid var(--line);
            color: var(--light1);
        }

        .button-line:hover {
            background-color: var(--light1);
            color: var(--line);
        }

        .button-fb {
            background-color: var(--facebook);
            border: 2px solid var(--facebook);
            color: var(--light1);
        }

        .button-fb:hover {
            background-color: var(--light1);
            color: var(--facebook);
        }

        .button-google {
            background-color: var(--google);
            border: 2px solid var(--google);
            color: var(--light1);
        }

        .button-google:hover {
            background-color: var(--light1);
            color: var(--google);
        }

        /* Sign up */
        .sign-up {
            border-radius: 0;
            background-color: var(--dark6);
            border: 2px solid var(--dark6);
            color: var(--light1);
        }

        .sign-up:hover {
            background-color: var(--light1);
            color: var(--dark6);
        }

        /* Invalid input */
        .invalid-msg {
            margin: 3px 0 5px 0;
            height: 12px;
            font-size: 12px !important;
            color: var(--warning) !important;
            background-color: transparent !important;
            transform: translateY(-8px);
        }

        .sign-up-inputs {
            .password .invalid-msg {
                transform: translateY(-28px);
            }

            .phone .invalid-msg {
                margin-left: calc(29% + 15px);
            }
        }

        .address-detail {
            .invalid-msg {
                transform: translateY(8px);
            }
            
            .zipcode .invalid-msg {
                transform: translateY(-8px);
            }
        }

        .invalid-input {
            border-color: var(--warning) !important;
            border-width: 2px !important;
            animation: shake 0.6s linear forwards;
        }

        .invalid-input:focus {
            outline: none;
        }
    }
    .content.sign-in-content {
        height: fit-content;
        padding-bottom: 40px;
    }

    // Inputs
    .sign-up-inputs {
        margin-top: 10px;

        .column {
            width: 49%;
        }
    }

    /* Forgot user name/password */
    #page-forgot {
        height: auto;

        .remind-msg {
            margin-left: 20px;

            a {
                margin: 10px 0;
            }

            div a:first-of-type {
                margin: 10px 5px;
            }

            button {
                align-self: center;
                margin: 30px 0 20px 0;
            }
        }

        button {
            margin-bottom: 10px;
        }

        .go-back {
            font-size: 14px;
            text-decoration: underline;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .forgot {
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 925px) {
        .sign-up.desktop { display: block !important; }
        .social .sign-up.mobile { display: none; }
    }

    @media only screen and (max-width: 620px) {
        align-items: flex-start;
        background-color: var(--light3) !important;

        .content {
            box-shadow: none !important;
            height: 100%;

            h2 { margin: 10px 0; }

            .close { margin-top: 15px; }

            .button1, .button2 {
                padding: 8px 0;
                font-size: 14px;
                width: 170px;
            }

            .submit { margin-bottom: 15px; }

            .divider { margin: 0 0 20px 0; }

            .social {
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 20px;
                width: 90%;
                
                button {
                    margin: 0 5px 10px 5px;

                    svg { font-size: 18px; }
                }
                .sign-up { display: block; }
            }

            .text-inputs {
                #dropdown-type-content {
                    width: calc(100% - 2px);
                }
    
                #dropdown-city-content,
                #dropdown-district-content {
                    width: calc(100% - 2px);
                    max-height: 250px;
                    overflow-y: scroll;
                }
            }
           
            .sign-up.desktop { display: none !important; }
        }

        .content.sign-up-content {
            width: 100vw;

            // Sign up
            .text-inputs {
                width:90%;

                input {
                    margin-bottom: 0;
                    padding: 12px 20px 8px 20px;
                }
                .password {
                    .row {
                        flex-direction: column;
                        width: 100%;
                    }
                    .column { width: 100%; }
                    .column:first-of-type { margin-bottom: 8px; }
                    input { width: calc(100% - 50px); }
                    svg { transform: translate(-20px, -31px); }
                    .invalid-msg { transform: translateY(-20px); }
                }
                .company { margin-bottom: 27px; }
                .gen-sex {
                    flex-direction: column;

                    .column { width: 100%; }
                    .gender {
                        margin-bottom: 27px;
                        width: calc(100% - 10px);
                    }
                }
                .phone {
                    margin-top: 0;

                    .phone-input {
                        flex-direction: column;

                        svg { transform: none; }
                    }
                    .phone-input-type, .phone-input-number { width: calc(100% - 10px); }
                    .phone-input-type .dropdown-text { padding-right: 10px; }
                    .phone-input-number {
                        margin-top: 8px;

                        div { width: 100%; }
                        input:first-of-type { width: 30%; }
                        input:last-of-type { width: 70%; }
                    }
                    .line { margin: 0 0 8px 0 !important; }
                    .line:last-of-type { margin: 0 !important; }
                    svg { transform: translate(0, 5px); }
                    .invalid-msg { margin-left: 15px; }
                }
                .phone-input { margin: 0; }
                .address-detail {
                    flex-direction: column;

                    .column { width: calc(100% - 10px); }
                    .dropdown-text { padding-right: 10px; }
                    .zipcode { width: 100%; }
                }
                .invalid-msg {
                    display: flex;
                    align-items: center;
                    margin: 7px 0 5px 15px;
                    height: 15px;
                    transform: none;
                }
            }
        }
    }

    @media only screen and (max-width: 435px) {
        .content {
            .button1, .button2 { width: 115px; }
        }
    }
}