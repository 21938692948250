#log-auth-actions {
    min-height: calc(100vh - 300px);

    /* Filter by user */
    .user-filter {
        .title {
            font-weight: bold;
        }

        .dropdown {
            margin: 0 0 0 10px;
            position: relative;
            width: 110px;
            cursor: pointer;

            svg {
                padding-left: 5px;
            }
        }

        .dropdown-text {
            border: 1px solid var(--dark5);
            padding: 5px 8px 5px 14px;
            width: 100%;
        }

        #dropdown-text {
            text-align: center;
            width: 100%;
            min-height: 24px;

            a {
                padding: 0;
                text-align: center;
            }
        }

        .dropdown-content {
            display: none;
            flex-direction: column;
            position: absolute;
            text-align: center;
            margin: 74px 0 0 -15px;
            border: 1px solid var(--dark5);
            width: 132px;
            z-index: 2;

            a {
                padding: 4px 0;
                width: 132px;
                background-color: var(--light3);
            }

            a:first-of-type {
                padding-top: 8px;
            }

            a:last-of-type {
                padding-bottom: 8px;
            }

            a:hover {
                background-color: var(--light4);
            }
        }
    }

    /* Log list */
    .log-list {
        margin-top: 20px;
        border: 1px solid var(--dark5);

        .title a {
            font-weight: bold;
            background-color: var(--dark5-1);
        }

        .title:hover {
            background-color: var(--dark5-1);
        }

        .log-list-content div:nth-of-type(2n) {
            background-color: var(--blue1);
        }

        div {
            a {
                min-width: 100px;
                border: 1px solid var(--dark5);
                padding: 5px 10px;
                text-align: center;
            }

            a:nth-of-type(3) {
                min-width: 170px;
            }

            a:last-of-type {
                width: 180px;
            }

            span.id {
                color: var(--facebook);
            }
        }

        div.no-record {
            border: 1px solid var(--dark5);
            padding: 7px 0;
        }

        div.log-row:hover {
            background-color: var(--sub3);
        }
    }
}