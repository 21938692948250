#edit-home {
    min-height: calc(100vh - 326px);

    .banners {
        margin-bottom: 80px;

        .title {
            font-size: 24px;
            font-weight: bold;
        }

        a {
            margin-bottom: 5px;
            font-size: 14px;
        }

        #carousel {
            margin-top: 5px;
            width: 700px;

            .indicator {
                width: 10px;
                height: 10px;
            }
        }

        .empty_banner {
            margin-top: 5px;
            width: 700px;
            height: 250px;
            border: 1px solid var(--dark5);
        }

        .inputs {
            margin-top: 20px;

            div {
                margin: 0 10px;
            }

            .input-title {
                margin: 0 8px 0 0;
            }

            input.order {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid var(--dark5);
                text-align: center;
                width: 60px;
            }

            input.order:focus {
                outline: none;
            }

            input.link-input {
                width: 200px;
                height: 19px;
                text-align: center;
            }

            input.link-input:focus {
                outline-color: var(--sub1);
            }

            // Error handling
            input.invalid-input {
                border: 2px solid var(--warning);
                animation: shake 0.6s linear forwards;
            }

            input.invalid-input:focus {
                outline: none;
            }

            // Buttons
            button {
                display: flex;
                align-items: center;
                border-width: 1px;
                border-radius: 3px;
                padding: 0 12px;
                font-size: 12px;
                height: 25px;
                border-color: var(--dark5);
                background-color: var(--dark5);
            }

            button:hover {
                background-color: var(--transparent);
                color: var(--dark5);
            }

            button.delete {
                margin-left: 20px;
                border-color: var(--red1);
                padding: 3px 8px;
                background-color: var(--red1);
            }

            button.delete:hover {
                background-color: var(--transparent);
                color: var(--red1);
            }

            button.add-link {
                margin-left: 10px;
            }
        }

        // File upload
        .file-input-container {
            position: relative;
            overflow: hidden;
            display: inline-block;
        }

        .file-input {
            position: absolute;
            font-size: 0;
            right: 0;
            top: 0;
            opacity: 0;
        }

        .upload {
            display: flex;
            align-items: center;
            margin-top: 20px;
            border-width: 1px;
            border-radius: 3px;
            padding: 0 12px;
            font-size: 12px;
            height: 25px;
            cursor: pointer;
        }

        // Loading
        .loading {
            margin: 20px 0 0 0;
            color: var(--sub1);
            animation: horizontalMove 2s linear infinite;
        }
    }

    .error-msg {
        text-align: center;
        color: var(--warning);
        margin-top: 20px;
        font-size: 14px;
    }
}