footer {
  background-color: var(--dark1);
  width: 100%;
  z-index: 1;

  .footer-content {
    width: 890px;
    padding: 10px 0;

    .field {
      margin: 20px 0;
      max-width: 138px;
    }

    .field-mobile {
      display: none !important;
    }

    .field-help {
      margin-left: -13px;
    }

    a {
      padding: 5px;
      color: var(--light1);
      font-size: 0.8em;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover { color: var(--sub1); }

    .footer-title {
      margin-bottom: 7px;
      padding: 1px 5px;
      background-color: var(--light1);
      color: var(--dark5);
      font-size: 1em;
      font-weight: bold;
      width: fit-content;
      cursor: auto;
    }

    .footer-title:hover {
      color: var(--dark5);
    }

    /* Icons */
    .footer-icons {
      margin-top: 12px;

      .icon {
        color: var(--light1);
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
      }
    }

    .icon-line {
      background-color: var(--line);
    }

    .icon-facebook {
      background-color: var(--facebook);
      margin: 0 10px;
    }

    .icon-instagram {
      background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    }

    /* Copyright */
    .copyright {
      margin-top: 15px;
      color: var(--light2);
      font-weight: 300;
      font-size: 14px;
      text-wrap: nowrap;
    }

    // Facebook
    .fb-page {
      margin: 20px 0;
      width: 300px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .footer-content {
      width: 80vw;
    }
  }

  @media only screen and (max-width: 925px) {
    .footer-content {
      flex-wrap: wrap;
      justify-content: space-around;
      width: 500px;

      .field {
        margin-left: 0 !important;
      }

      .field-help {
        margin-left: -15px !important;
      }

      .fb-page {
        margin: 10px 0 0 0;
        width: fit-content;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .footer-content {
      justify-content: flex-start;
      max-width: calc(100vw - 50px);
      width: 80vw;

      .footer-title {
        text-underline-offset: 4px;
      }

      .footer-icons {
        margin: 5px 0 0 0;
      }

      .field-desktop {
        display: none !important;
      }

      .field-mobile {
        display: flex !important;
      }

      .field-help {
        margin-left: 0 !important;
      }

      .last-field {
        margin-top: 0;
      }

      .fb-page {
        margin-bottom: 25px;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .footer-content .fb-page {
      margin-left: calc((100vw - 300px) / 2 - 30px) !important;
    }
  }
}