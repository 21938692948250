#contact-messages {
    min-height: calc(100vh - 300px);

    /* Message list */
    .message-list {
        margin-top: 20px;
        border: 1px solid var(--dark5);
        padding: 8px;
        min-width: 800px;

        .record {
            .title{
                font-weight: bold;
                width: 80px;
            }

            .row {
                padding: 3px 10px;
            }

            .row:hover {
                background-color: var(--sub3);
            }

            .row-a {
                background-color: var(--blue1);
            }

            a {
                width: 100%;
            }
        }

        div.no-record {
            padding: 7px 0;
        }
    }
}