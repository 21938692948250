#category-content {
    margin-top: 40px;
    border: 1px solid var(--dark2);
    padding-bottom: 30px;

    div {
        margin-bottom: 5px;
        width: 150px;
    }

    a {
        margin: 8px 0;
        width: 150px;
        cursor: pointer;
    }

    a.title {
        margin: 0 0 15px 0;
        padding: 15px 45px;
        border-bottom: 1px solid var(--dark2);
        font-weight: bold;
        font-size: 22px;
        background-color: var(--dark11-4);
        text-align: center;
        cursor: auto;
    }

    svg {
        color: var(--dark5-8) !important;
        cursor: auto;
    }

    .main-list {
        font-size: 18px;
        font-weight: bold;
    }

    .main-list:hover {
        color: var(--main);
    }

    .sub-list {
        margin-left: 17px;
        color: var(--dark6-9);
    }

    .sub-list:hover {
        color: var(--sub1);
    }
}