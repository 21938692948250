nav {
  position: fixed;
  background-color: var(--light3);
  top: 38px;
  left: 0;
  width: calc(100vw - 60px);
  height: 68.5px;
  padding: 0 30px;
  border-bottom: 2px solid var(--dark12);
  z-index: 25;

  a, .nav-main {
    color: var(--dark1);
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
  }

  // Dropdown
  .dropdown .dropdown-content {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: var(--light3);
    z-index: 25;

    .dropdown-content-main {
      border-top: 2px solid var(--dark12);
    }

    div a {
      font-size: 13px;
    }

    div a:hover {
      color: var(--sub1);
    }
  }

  .dropdown:hover .dropdown-content {
    display: flex;
  }

  // Logo
  .nav-logo {
    img {
      height: 40px;
      cursor: pointer;
    }

    img:last-of-type {
      height: 30px;
      margin-left: 5px;
      opacity: 0.9;
    }
  }

  // Menu
  .nav-menu {
    padding: 25px 0 20px 0;

    .divider {
      background-color: var(--dark2);
      margin: 0 1em;
      opacity: .3;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }

    .dropdown-content {
      min-width: 100%;
      left: 0;
      padding: 20px 0 10px 0;
      box-shadow: 5px 5px 10px var(--shadow);
      clip-path: inset(0 -30px -30px -30px);

      .dropdown-title {      
        color: var(--dark2);
        font-size: 16px;
      }

      div {
        a {
          padding: 5px 0;
        }

        div:last-of-type {
          margin-left: -3%;
        }
      }

      nav .nav-menu .dropdown-content .dropdown-title:hover {
        font-weight: bold;
        color: var(--main);
      }

      img {
        width: 350px;
        margin: 10px 0 5px 0;
      }

      .dropdown-img a:hover {
        color: var(--dark1);
        cursor: auto;
      }
    }

    .dropdown-content-main {
      justify-content: space-around;
      padding-top: 10px;
    }

    .nav-main:hover,
    .dropdown:hover .nav-product {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 10px;
      position: relative;
      z-index: 26;
    }
  }

  // Nav right
  .nav-user {
    .icon {
      color: var(--dark1);
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
    }

    .icon:hover {
      color: var(--sub1);
    }

    .dropdown:hover svg {
      color: var(--sub1);
    }

    // User
    .dropdown svg {
      margin-top: 3.33px;
    }

    .dropdown-content { padding-top: 21.5px; }

    .dropdown-content-main {
      border: 2px solid var(--dark12) !important;
      padding-bottom: 10px;
    }

    .nav-user-dropdown {
      position: absolute;
      right: 18px;

      a {
        display: block;
        padding: 10px 25px 0 25px;
        width: 115px;
      }

      a.user-info { color: var(--dark9); }
      a.user-info:hover {
        color: var(--dark9);
        cursor: auto;
      }
    }

    // Shopping cart
    .cart-container:hover .icon-cart,
    .cart-container:hover .cart-num { color: var(--sub1); }

    .cart-container {
      transform: translateY(-2px);

      .icon-cart {
        margin-right: 20px;
        font-size: 24px;
      }

      .cart-num { margin: 5px 0 0 -18px; }

      .dropdown-content {
        right: -16px;
        margin-top: 22px;
        padding: 10px 20px;
        border: 2px solid var(--dark2);

        a {
          width: 30px;
          text-align: center;
          padding: 5px 10px;
        }

        a:first-of-type {
          width: 200px;
        }

        .title {
          padding-bottom: 3px;
          margin-bottom: 5px;
          border-bottom: 1px solid var(--dark2);

          a {
            font-weight: bold;
          }
        }

        .color {
          margin: 0 7.5px;
          width: 15px;
          height: 15px;
        }
      }

      // Successfully add to cart
      .dropdown-content .add-success {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid var(--dark5);

        div {
          background-color: var(--sub8);
          border-radius: 50%;
          width: 16px;
          height: 16px;
        }

        svg {
          margin: 0 4px 0 -17px;
          font-size: 20px;
          color: var(--sub7);
        }

        a {
          width: auto;
          padding-left: 3px;
        }
      }
    }

    .cart-container.empty {
      margin-right: -20px;

      .dropdown-content {
        margin-right: 20px;
      }
    }
  }

  // Search bar
  .input {
    margin-right: 23px;
    border: 1.5px solid var(--dark1);
    padding: 5px 10px;
    z-index: 26;

    .icon {
      margin: 0;
    }
  }

  input {
    border: none;
    height: 24px;
    width: 157px;
    margin-right: 3px;
  }

  input:focus {
    outline: none;
  }

  @media only screen and (max-width: 925px) {
    width: calc(100vw - 50px);
    padding: 0 20px 0 30px;
    height: 62px;

    // Dropdown Menu
    .nav-menu {
      position: relative;
      padding: 0;

      .blur-background {
        visibility: hidden;
        position: fixed;
        backdrop-filter: blur(10px);
        background: var(--dark6-5);
        width: 120vw;
        height: 120vh;
        top: 0;
        left: 0;
        right: 0;
        z-index: 27;
        transition: all 0.5s ease-in-out;
      }

      .dropdown {
        visibility: hidden;
        background-color: var(--light3);
        position: fixed;
        top: 0;
        right: 0;
        padding: 15px 40px 10px 0;
        width: 275px;
        height: 120vh;
        z-index: 28;
        transform: translateX(100%);
        transition: all 0.5s ease-in-out;

        a {
          display: none;
          margin-left: 10px;
          padding: 5px 0;
          color: var(--dark9);
        }

        a:before {
          content: "> ";
          margin-right: 3px;
          color: var(--dark9);
        }

        .nav-main:hover { text-decoration: none; }

        .dropdown-title,
        .nav-main {
          display: block;
          margin: 0;
          padding: 0;
          font-weight: 600;
          color: var(--dark5);
        }

        .dropdown-title:before,
        .nav-main:before { content: none; }

        .dropdown-title:after {
          position: absolute;
          content: "▸";
          right: 45px;
        }

        .dropdown-title.active:after { content: "▾"; }

        .category-cont.active {
          border-bottom: 1px solid var(--dark11);
          padding-bottom: 10px;
          width: 315px;

          .dropdown-title { margin-bottom: 10px; }
        }
        .category-item { padding: 10px 0 10px 55px; }

        .close-icon {
          align-self: flex-end;
          margin: 0 0 20px 0;
          transform: translateX(25px);
        }

        .dropdown-item {
          border-bottom: 1px solid var(--dark11);
          padding: 15px 0 15px 55px;
          width: 315px;
        }

        .qa {
          padding: 15px 0 0 55px;
        }
      }

      .blur-background.active { visibility: visible; }

      .dropdown.active {
        visibility: visible;
        transform: translateY(0);
      }
    }

    // Menu
    .nav-user {
      padding: 15px 0;

      .menu-items {
        .icon { margin-right: 10px; }
        .icon:first-of-type { margin-right: 12px; }
        .icon:last-of-type { margin-right: 0; }
      }

      .menu-icon {
        margin: 0;
        font-size: 30px;
      }

      .dropdown-content { display: none !important; }
      .dropdown:hover svg { color: inherit; }

      .user-cont .dropdown-item:last-of-type { border: 0; }
    }

    // Search bar
    .input {
      margin: 20px 0 0 30px;
      width: 242px;
      overflow: visible;

      input {
        width: 212px;
        height: 30px;
        font-size: 16px;
      }
    }

    .nav-user-icon, .cart-container { display: none; }
  }

  @media only screen and (max-width: 550px) {
    padding: 0 15px;
    width: calc(100vw - 30px);
  }

  @media only screen and (max-width: 400px) {
    .nav-logo {
      img { height: 30px; }
      img:last-of-type { height: 22.5px; }
    }
  }
}