#page-management {
    .page-content {
        margin: 0;
        padding: 60px 0;
        width: 100vw;
        min-height: calc(100vh - 326px);
        background-color: var(--light1);
        box-shadow: 1px 1px 15px var(--shadow);
    }

    .content {
        justify-content: center;
    }

    /* Sign in */
    .content,
    .content .text-inputs a,
    .content input {
        background-color: var(--light1) !important;
    }

    @media only screen and (max-width: 1400px) {
        .page-content {
            margin: 0;
            width: 100vw;
        }
    }
}