#edit-order-detail {
    z-index: 25;

    .modal-content {
        width: 800px;
        height: 75vh;
        padding: 20px 50px 50px 50px;
        position: relative;

        h2 {
            margin: 0 0 20px 0;
            text-align: center;
        }

        .content-wrap {
            width: 100%;
        }

        .block {
            margin-bottom: 30px;
            border: 1px solid var(--dark3);
        }

        /* Fields */
        .field-title {
            background-color: var(--dark11-4);
            padding: 5px 20px;

            h3 {
                margin: 0;
                font-size: 16px;
            }

            button {
                border: 1.5px solid var(--dark6);
                padding: 2px 6px;
                background-color: var(--light2);
                font-weight: normal;
                cursor: pointer;
            }

            button:hover {
                background-color: var(--dark6);
                color: var(--light2);
            }

            .confirm {
                background-color: var(--dark6);
                border-color: var(--dark6);
                color: var(--light2);
            }

            .confirm:hover {
                background-color: var(--light2);
                color: var(--dark6);
            }
        }

        .field {
            padding: 15px 20px;

            a {
                margin-bottom: 5px;
                overflow-wrap: break-word;

                span.canceled {
                    font-weight: 500;
                    color: var(--warning);
                }
            }

            .title {
                margin-right: 10px;
                font-weight: bold;
            }

            .column {
                margin-bottom: 5px;

                .content {
                    margin-left: 15px;
                }
            }
        }

        /* Tracking & Shipping Info */
        .tracking {
            width: 48%;
        }

        .shipping {
            width: 48%;

            .store-content {
                margin: 5px 0 5px 11px;
                padding: 15px 20px;
                background-color: var(--dark4);

                b {
                    margin-right: 5px;
                }
            }
        }

        /* Order Detail List */
        .order-detail-list {
            a:first-of-type {
                margin-right: 5px;
                width: 130px;
                font-weight: bold;
            }
        }

        /* Status */
        .status {
            margin: 10px 0;
            width: 800px;

            text {
                font-weight: bold;
            }
        }

        /* Order detail */
        .order-detail {
            .table {
                display: none !important;
                margin: 0;
                padding: 10px 0 25px 0;
            }

            .table.show {
                display: flex !important;
                border-bottom: 1px solid var(--dark11-8);
            }

            .row {
                padding: 10px 0;

                img {
                    margin-right: 15px;
                    width: 80px;
                    height: 80px;
                    background-color: var(--light3);
                    object-fit: contain;
                }

                a {
                    margin-bottom: 0;
                }

                .name {
                    margin: 0 0 5px 0;
                    font-size: 14px;
                    text-wrap: nowrap;
                    font-weight: 500;

                    a:first-of-type {
                        margin-right: 5px;
                    }

                    a:last-of-type {
                        font-size: 12px;
                    }
                }

                .specs-div {
                    margin-bottom: 5px;
                    color: var(--dark13);
                    font-size: 12px;

                    a:first-of-type {
                        margin-right: 5px;
                    }
                }

                .price-div {
                    width: 100%;

                    .price {
                        margin-right: 5px;
                        font-size: 14px;
                        opacity: 1;
                    }

                    .sale {
                        font-size: 14px;
                        color: var(--red);
                    }
                }
            }

            .item {
                .item-content {
                    width: 100%;
                }

                .info {
                    width: 250px;
                }

                .price {
                    opacity: 0.6;
                    text-decoration: line-through;
                }
            }
        }

        /* Summary */
        .summary-wrap {
            width: calc(100% - 50px);
            height: fit-content;
            padding: 25px;
            background-color: var(--dark4);

            .calculation {
                margin: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid var(--dark2);

                a {
                    padding: 5px 0;
                }

                .discount {
                    color: var(--red);
                }
            }

            .summary {
                padding: 5px 0;
                font-weight: bold;
                font-size: 20px;
            }
        }

        /* Icons */
        .icon {
            font-size: 20px;
            color: var(--dark6-7);
            cursor: pointer;
        }

        .icon:hover {
            color: var(--sub1) !important;
        }

        .close {
            align-self: flex-end;
            margin-bottom: 10px;
            transform: translateX(35px);
        }

        .close:hover {
            color: var(--sub1);
        }

        /* Input */
        input {
            margin: 0px 0 5px 15px;
            padding: 5.5px 10px;
            border: 1px solid var(--dark5);
            border-radius: 5px;
            font-size: 15px;
            width: 150px;
            text-align: center;
        }

        /* Dropdown */
        .dropdown {
            position: relative;
            margin: 0px 0 5px 15px;
            font-size: 15px;

            a {
                color: var(--dark6);
            }

            svg {
                font-size: 16px;
                transform: none;
                cursor: pointer;
            }
        }

        .dropdown-text {
            border: 1px solid var(--dark5);
            border-radius: 5px;
            padding: 5px 12px 5px 5px;
            height: 20px;
            min-width: 153px;

            a {
                margin: 0 !important;
                padding: 0;
                width: 100%;
                text-align: center;
                cursor: pointer;
            }
        }

        .dropdown-content {
            display: none;
            position: absolute;
            flex-direction: column;
            margin-top: 30px;
            border: 1px solid var(--dark5);
            border-radius: 0 0 5px 5px;
            padding: 8px 0;
            z-index: 2;
            background-color: var(--light2);
            width: 170px;

            a {
                margin: 0;
                margin: 0 5px;
                padding: 2px 15px;
                cursor: pointer;
            }

            a:last-of-type {
                margin-bottom: 0;
            }

            a:hover {
                color: var(--light1);
                background-color: var(--sub1);
            }
        }

        /* Cancel button */
        button.cancel {
            align-self: center;
            margin-top: 20px;
            border-color: var(--google);
            border-radius: 5px;
            padding: 5px 30px;
            width: fit-content;
            font-size: 16px;
            font-weight: 500;
            background-color: var(--google);
        }

        button.cancel:hover {
            background-color: var(--light2);
            color: var(--google);
        }
    }
}