#page-contact {
	.page-content {
		width: 805px;
		min-width: 805px;
	}

	h2 {
		margin-top: 0;
		align-self: center;
	}

	.content {
		width: 100%;
		margin: 50px 0;
		border-radius: 12px;
		border: 1px solid var(--dark2);
		padding: 50px 60px;
		background-color: var(--light3);
		box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);

		.title {
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		a {
			margin-bottom: 15px;
		}

		.divider {
			width: 100%;
			margin: 30px 0 40px 0;
			border-bottom: 1px solid var(--dark5-6);
		}

		/* Fields */
		.field.online {
			a span {
				margin-left: 5px;
				font-weight: bold;
				opacity: 0.9;
			}
		}

		.field.phones {
			.title {
				margin-bottom: 10px;
			}

			a {
				margin-bottom: 4px;

				svg {
					margin: 1px 2px 0 2px;
					font-size: 18px;
				}
			}

			.chats {
				margin-top: 15px;
			}
		}

		/* Button */
		button {
			margin-right: 20px;
			border-width: 3px !important;
			border-radius: 10px;
			padding: 5px 0;
			width: 180px;
			font-size: 16px;
			transition: all 0.3s;
		}

		.button2 svg {
			font-size: 24px;
			margin-right: 5px;
		}

		.button-line {
			background-color: var(--line);
			border: 2px solid var(--line);
			color: var(--light1);
		}

		.button-line:hover {
			background-color: var(--light1);
			color: var(--line);
		}

		.button-fb {
			background-color: var(--facebook);
			border: 2px solid var(--facebook);
			color: var(--light1);
		}

		.button-fb:hover {
			background-color: var(--light1);
			color: var(--facebook);
		}

		/* Form */
		.form {
			.row {
				width: 100%;

				div {
					width: 48%;
				}
			}

			a {
				margin-left: 15px;
				padding: 0 5px;
				width: fit-content;
				background-color: var(--light3);
				color: var(--dark6-7) !important;
				font-size: 15px;
				z-index: 1;
				margin-bottom: 0;
			}

			span {
				margin-left: 2px;
				color: var(--warning);
			}

			input {
				margin-top: -11.11px;
				margin-bottom: 18px;
				border: 1px solid var(--dark2);
				border-radius: 5px;
				padding: 10px 25px;
				height: 20px;
				font-size: 16px;
				text-align: center;
				background-color: var(--light3);
			}

			textarea {
				margin-top: -11.11px;
				margin-bottom: 18px;
				border: 1px solid var(--dark2);
				border-radius: 5px;
				padding: 20px;
				min-height: 200px;
				background-color: var(--light3);
				font-size: 16px;
				resize: none;
			}

			input:focus,
			textarea:focus {
				outline-color: var(--sub1);
			}

			button {
				margin: 20px 0 10px 0;
				align-self: center;
				background-color: var(--dark6);
				border: 2px solid var(--dark6);
				border-radius: 0;
				padding: 8px 0;
				color: var(--light1);
				width: 170px;
				font-size: 14px;
			}

			button:hover {
				background-color: var(--light1);
				color: var(--dark6);
			}
		}

		/* Invalid Input */
		.invalid-input {
			border-color: var(--warning) !important;
			animation: shake 0.6s linear forwards;
		}

		.invalid-input:focus {
			outline: none;
		}

		.error-msg {
			align-self: center;
			color: var(--warning);
			margin: 10px 0 0 0 !important;
			font-size: 12px;
		}

		.captcha {
			margin-top: 20px;
			align-self: center;
		}
	}

	@media only screen and (max-width: 1025px) {
		.page-content {
			width: calc(100% - 120px);
			min-width: auto;
			min-height: fit-content;
			margin-bottom: 0;
		}

		.content {
			margin: 0;
			border-radius: 0;
			border: 0;
			padding: 40px 60px;
		}
	}

	@media only screen and (max-width: 925px) {
		.content {
			padding: 45px 60px;
		}
	}

	@media only screen and (max-width: 600px) {
		.content .form .row {
			flex-direction: column;

			div {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: 500px) {
		.page-content {
			width: calc(100% - 60px);
		}

		.content {
			padding: 30px;

			.chats {
				flex-direction: column;
				margin-top: 5px;

				button:last-of-type {
					margin-top: 15px;
				}
			}
		}
	}
}