#page-product-info {
	background-color: var(--light3);

	.page-content {
		margin: 0;
		max-width: 1350px;
		width: 93vw;
		padding-top: 20px;
	}

	.info {
		width: 1030px;
	}

	/* [ Images ] */
	.product-images {
		margin-top: 40px;
		border: 1px solid var(--dark2);
		width: 515px;
		height: fit-content;

		.current-img-container {
			width: 100%;

			.current_image {
				margin-bottom: 30px;
				width: 400px;
			}

			// Image magnifier
			.image-magnifier-container {
				position: relative;

				.cursor_square {
					position: absolute;
					border: 2px solid var(--light3-8);
					background-color: var(--light3-5);
					pointer-events: none;
				}
	
				.zoomed_image_cont {
					position: absolute;
					top: -1px;
					left: 457px;
					width: 515px;
					height: 430px;
					border: 1px solid var(--dark2);
					background: var(--light3);
					overflow: hidden;
					z-index: 2;

					.current_image.zoomed {
						margin-bottom: 0;
					}
				}
			}
		}

		.image-list-container {
			width: 100%;
			border-top: 1px solid var(--dark2);

			button {
				background-color: var(--dark11-4);
			}

			button:first-of-type {
				border: 0;
				border-right: 1px solid var(--dark2);
			}

			button:last-of-type {
				border: 0;
				border-left: 1px solid var(--dark2);
			}

			svg {
				font-size: 40px;
				color: var(--dark5);
				cursor: pointer;
			}

			svg:hover {
				opacity: 0.4;
			}

			.image-list {
				margin: 0 10px;
				width: 100%;
				overflow-x: auto;
				scrollbar-width: none; /* Hide scrollbar for Firefox */

				img {
					margin: 10px 5px;
					padding: 0 10px;
					border: 2px solid transparent;
					width: 80px;
					cursor: pointer;
				}

				img:hover {
					border: 2px solid var(--dark2-9);
				}

				img.current {
					border: 2px solid var(--dark5);
				}
			}

			/* Hide scrollbar for Chrome, Safari, and Opera */
			.image-list::-webkit-scrollbar {
				display: none;
			}
		}
	}

	/* [ Details ] */
	.detail {
		padding: 20px 0 20px 40px;
		width: 515px;

		h2 {
			padding: 0 0 15px 5px;
			border-bottom: 1px solid var(--dark2);

			a {
				font-weight: 500;
			}
		}

		.title {
			margin: 8px 10px 8px 0;
			font-weight: bold;
		}

		.block {
			margin-bottom: 15px;

			.content {
				margin-left: 15px;
			}
		}

		/* Price */
		.price {
			margin: 0 0 -2px 15px;
			font-size: 16px;

			div {
				position: relative;
				margin-bottom: 5px;

				a {
					position: relative;
				}

				a:first-of-type {
					font-weight: bold;
				}

				a:last-of-type {
					align-items: baseline;
					margin: 0 0 0 16px;
					color: var(--red1);
					font-size: 20px;
					font-weight: 500;
					letter-spacing: -0.5px;
					width: fit-content;
				}

				a.strikethrough {
					color: var(--dark13);
					font-weight: 300;
				}

				a.strikethrough::after {
					content: '';
					position: absolute;
					left: -3px;
					top: 50%;
					width: calc(100% + 6px);
					height: 1.5px;
					background: var(--dark13);
					transform: translateY(-50%);
				}

				span {
					font-size: 16px;
				}

				span.currency {
					padding-right: 5px;
					transform: translateY(-4px);
				}

				span.number {
					font-size: 30px;
					letter-spacing: -1px;
				}
			}

			div.original-price {
				a:first-of-type {
					text-decoration: line-through;
					color: var(--dark13);
				}

				a:last-of-type {
					margin-bottom: 5px;
					font-weight: 300;
				}
			}
		}

		/* Deal */
		.deal {
			margin: 5px 0 20px 15px;

			div {
				padding: 3px 6px 3px 10px;
				background-color: var(--sub4);
				color: var(--light1);
			}

			.text {
				padding: 3px 8px;
				color: var(--sub6);
				background-color: var(--sub4-4);
			}

			svg {
				margin-left: 2px;
			}
		}

		/* Feature */
		.feature {
			margin: 8px 0 8px 15px;

			div {
				margin: 0 0 8px 15px;

				a.bullet {
					width: 7px;
					height: 7px;
					background-color: var(--dark5);
					margin-right: 7px;
				}
			}
		}

		/* Color */
		.color-wrapper {
			margin-left: 15px;

			.color {
				margin-right: 7px;
				cursor: pointer;
				width: 30px;
				height: 30px;

				div {
					width: 20px;
					height: 20px;
					opacity: 0.8;
				}
			}

			.color:hover div {
				opacity: 1;
			}
		}


		/* Size */
		.size {
			margin-left: 15px;

			.content {
				flex-wrap: wrap;
				margin-top: -10px;

				button {
					width: 60px;
					height: 35px;
					margin: 10px 10px 0 0;
					border: 1px solid var(--dark3);
					background-color: transparent;
					font-size: 18px;
					cursor: pointer;
				}

				button.selected {
					background-color: var(--dark5);
					border-color: var(--dark5);
					color: var(--light1);
				}

				button:disabled {
					opacity: 0.3;
					cursor: not-allowed;
				}

				button:hover {
					border-color: var(--dark5);
					background-color: var(--dark4-8);
				}

				button:disabled:hover {
					border-color: var(--dark3);
					background-color: transparent;
				}
			}

			.size-warning {
				margin: 10px 0 0 15px;
				color: var(--warning);
				font-weight: 500;
				animation: shake 0.6s linear forwards;
			}
		}

		/* Quantity */
		.quantity {
			margin-left: 15px;

			input {
				border: 0;
				border-left: 1px solid var(--dark3);
				border-right: 1px solid var(--dark3);
				font-size: 18px;
				text-align: center;
				height: calc(100% - 2px);
				width: 70px;
			}

			input:focus {
				outline: none;
			}

			.content {
				margin-left: 20px;
				border: 1px solid var(--dark3);
				width: fit-content;

				button {
					background-color: var(--dark4-8);
					border: 0;
					padding: 0 10px;
					height: 40px;
					cursor: pointer;
				}

				button:hover {
					background-color: var(--dark4);
				}

				button:hover svg {
					color: var(--dark6);
				}
			}

			svg {
				color: var(--dark6-7);
				font-size: 23px;
				cursor: pointer;
			}
		}
	}

	/* Button - Add to cart / Check out now */
	.buttons {
		margin: 15px 0 20px 0;
		width: 100%;

		button {
			margin-right: 30px;
			border-radius: 5px;
			padding: 10px 0;
			width: 200px;
			font-size: 18px;
			background-color: var(--light3);
			cursor: pointer;
		}

		button:disabled {
			opacity: 0.3;
			cursor: not-allowed;
		}

		.add-to-cart {
			border: 2px solid var(--google);
			color: var(--google);

			svg {
				margin-right: 5px;
				font-size: 20px;
			}
		}

		.add-to-cart:hover {
			font-weight: bold;
		}

		.add-to-cart:disabled:hover {
			font-weight: normal;
		}

		.check-out {
			border: 2px solid var(--google);
			background-color: var(--google);
			color: var(--light3);
		}

		.check-out:hover {
			opacity: 0.8;
		}

		.check-out:disabled:hover {
			opacity: 0.3;
		}
	}

	/* Shops */
	.shops {
		.discount {
			padding: 3px 10px;
			color: var(--red);
			border: 1px solid var(--red);
			background-color: var(--red-2);
			font-size: 18px;
		}

		.divider {
			margin-top: 5px;
			font-size: 18px;

			svg {
				color: var(--red);
			}
		}

		.buttons {
			margin-top: 10px;
		}

		button {
			width: 140px;
			margin-bottom: 15px;
			padding: 8px 10px;
			border: 1px solid var(--dark2);
			box-shadow: 3px 3px 10px var(--shadow);
			cursor: pointer;
		}

		button:last-of-type {
			margin-right: 0;
		}

		button:hover {
			border: 1px solid var(--sub1);
			background-color: var(--sub3);
			color: var(--sub1);
		}

		.invalid {
			opacity: 0.2;
			cursor: not-allowed;
		}

		.invalid:hover {
			border: 1px solid var(--dark2);
			background-color: transparent;
			color: var(--dark6);
		}

		img {
			margin-right: 5px;
			width: 30px;
		}
	}

	/* [ Description ] */
	.description {
		margin-top: 30px;

		.content {
			margin: 0 0 10px 25px;
		}

		.block {
			margin-bottom: 30px;
		}

		.title {
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 18px;
		}

		/* Title list */
		.title-list {
			margin-bottom: 35px;
			border-top: 1px solid var(--dark2);
			border-bottom: 1px solid var(--dark2);
			padding: 10px 15px;
			background-color: var(--dark11-4);

			a {
				margin: 0 20px;
				font-size: 20px;
				cursor: pointer;
			}

			a:hover {
				text-decoration: underline;
				text-decoration-thickness: 2px;
				text-underline-offset: 8px;
			}
		}

		/* Table */
		.table {
			margin-left: 18px;

			.row {
				border-left: 1px solid var(--dark3);
			}

			.column {
				padding: 5px 15px;
				border-top: 1px solid var(--dark3);

				a {
					line-height: 25px;
					height: 25px;
				}
			}

			.column:first-of-type {
				border-width: 1px;
				background-color: var(--dark4);
				font-weight: bold;
			}

			.column:nth-of-type(2) {
				border-width: 1px;
			}

			.row:last-of-type .column {
				border-right: 1px solid var(--dark3);
			}

			.column:last-of-type {
				border-bottom: 1px solid var(--dark3);
			}

			.column .number {
				margin: 0 2px;
				line-height: 13px;
				font-size: 13px;
			}
		}

		/* 安心小檔案 */
		.file {
			div {
				margin-bottom: 5px;
			}

			svg {
				margin: 0 3px 0 15px;
				color: var(--yellow);

				path {
					fill: var(--yellow);
				}
			}
		}

		img {
			max-width: 600px;
			width: 100%;
		}
	}

	/** Loader **/
	.loader-cont {
		margin-top: 120px;
		width: 100%;
	}

	@media only screen and (max-width: 1400px) {
		.page-content {
			justify-content: center;

			#category-side-bar {
				display: none !important;
			}
		}
	}

	@media only screen and (max-width: 1050px) {
		.product-images {
			width: 420px;

			.current-img-container {
				.current_image {
					margin: 0;
					width: 325px;
				}

				// Image magnifier
				.image-magnifier-container .zoomed_image_cont {
					left: 373px;
					width: 515px;
				}
			}
		}

		.detail {
			width: 460px;
			padding-left: 30px;
		}
	}

	@media only screen and (max-width: 975px) {
		.page-content {
			padding: 0;
			width: 100vw;

			.info {
				align-items: center;

				.info-wrapper {
					flex-direction: column;
				}

				.product-images {
					margin: 0;
					width: 100vw;

					.cursor_square, .zoomed_image_cont { display: none !important; }

					.carousel {
						position: relative;
						max-width: 100%;
						overflow: hidden;
						touch-action: pan-y;

						img {
							margin: 0 calc((100vw - 400px) / 2);
							width: 400px;
							display: block;
						}
					}

					.carousel-inner {
						display: flex;
						transition: transform 0.4s ease;
					}

					.carousel-item {
						min-width: 100%;
						box-sizing: border-box;
					}

					.image-indicator {
						margin-top: -5px;
						margin-bottom: 15px;
						border-radius: 15px;
						padding: 3px 13px 3px 17px;
						font-size: 18px;
						letter-spacing: 3px;
						height: 20px;
						background: var(--dark6-9);
						color: #fff;
						z-index: 1;
					}
				}

				.detail {
					margin: 0 auto;
					padding: 0;
					width: fit-content;
					max-width: 90vw;

					.title {
						margin: 0;
						font-weight: 500;
						color: var(--dark6);
					}

					.block .content {
						margin-left: 36px;
					}

					// Product Name
					h2 {
						display: flex;
						flex-direction: column;
						margin: 15px 0 10px 0;
						border: 0;
						padding: 0;
						text-align: center;

						a {
							font-size: 22px;
						}
					}

					// Deals
					.deal {
						margin-left: 0;
						width: 90vw;

						div {
							padding: 0 2px;
							height: 100%;

							a {
								display: none !important;
							}
						}

						.text {
							padding: 3px 5px;
							font-size: 14px;
						}
					}
				}

				// Price
				.price {
					margin: 0 0 15px 0;

					div {
						flex-direction: row;
						align-items: center;

						a:first-of-type {
							font-weight: 500;
							color: var(--dark6);
						}

						a:last-of-type {
							margin-left: 20px;
							font-size: 18px;
						}

						span.currency {
							transform: translateY(-3px);
						}

						span.number {
							font-size: 26px;
						}
					}
				}

				// Color
				.color-wrapper {
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					margin-left: 0;

					.title {
						justify-self: flex-start;
					}
				}

				// Size
				.size {
					margin-left: 0;
					width: 100%;

					.block {
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
					}

					.title {
						white-space: nowrap;
						align-self: flex-start;
						line-height: 38px;
					}

					.content {
						flex-wrap: wrap;

						button {
							margin: 15px 15px 0 0;
							width: 55px;
							height: 28px;
							font-size: 14px;
						}
					}

					.size-warning {
						margin: 0 0 20px 0;
						text-align: center;
					}
				}

				// Quantity
				.quantity {
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					margin-left: 0;

					input {
						width: 60px;
						font-size: 16px;
						height: 23px;
					}

					button {
						justify-content: center;
						width: 28px;
						height: 25px;

						svg {
							font-size: 16px;
						}
					}
				}

				// Feature
				.feature {
					margin-left: 0;

					div {
						margin-left: 5px;
					}
				}

				// Button - Add to cart / Check out now
				.buttons.ac-buttons {
					justify-content: center;
					position: fixed;
					left: 0;
					bottom: 0;
					margin: 0;
					padding: 13px 0 10px 0;
					border-top: 1px solid var(--dark2);
					background-color: var(--light3);
					width: 100vw;
					z-index: 1;

					button {
						margin: 0 8px;
						padding: 6px 0;
						width: 120px;
						font-size: 14px;

						svg {
							font-size: 15px;
						}
					}
				}

				// Shops
				.shops {
					margin-top: 10px;

					.discount {
						font-size: 16px;
					}

					.buttons {
						justify-content: flex-start;
						overflow-x: scroll;
						width: fit-content;
						/* Hide scrollbar for Firefox */
						scrollbar-width: none;

						button {
							margin-right: 13px;
							padding: 6px 10px;
							min-width: 110px;
							font-size: 14px;
						}

						button:last-of-type {
							margin-right: 0;
						}

						img {
							width: 20px;
						}
					}

					/* Hide scrollbar for Chrome, Safari, and Opera */
					.buttons::-webkit-scrollbar {
						display: none;
					}
				}
			}

			// Descriptions
			.description {
				margin-top: 0;
				overflow: hidden;
				width: 100vw;

				.title-list {
					justify-content: space-around;

					a {
						margin: 0;
						font-size: 16px;
					}
				}

				.title {
					font-size: 16px;
					font-weight: 500;
					color: var(--dark6);
				}

				.content {
					margin-left: 0;
					padding: 0 20px;
				}

				.detail-images img {
					width: 100%;
				}

				.table {
					margin: 0;
					width: 100%;
					overflow-x: scroll;
					-ms-overflow-style: none;
					scrollbar-width: none;

					.table-wrapper {
						margin-left: 18px;
					}

					.column a {
						white-space: nowrap
					}
				}

				.table::-webkit-scrollbar {
					display: none;
				}
			}
		}
	}

	@media only screen and (max-width: 800px) {
		.page-content .info .shops .buttons {
			width: 90vw;
		}
	}

	@media only screen and (max-width: 500px) {
		.page-content .info .product-images .carousel img {
			margin: 0 calc((100vw - 350px) / 2);
			width: 350px;
		}
	}
}