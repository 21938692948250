#side-buttons .button {
    position: fixed;
    cursor: pointer;
    right: 5px;
    width: 40px;
    height: 40px;
    z-index: 20;
    box-shadow: 0 0 15px var(--shadow);
    border-radius: 50%;
}

#side-buttons .button svg {
    color: var(--light2);
    font-size: 22px;
}

#side-buttons .button:hover svg {
    animation: tilt 0.6s linear forwards;
}

/* Facebook chat */
#side-buttons .facebook-chat {
    bottom: 205px;
    background-color: var(--facebook1);
}

/* Line chat */
#side-buttons .line-chat {
    bottom: 160px;
    background-color: var(--line);
}

/* Message */
#side-buttons .message {
    position: fixed;
    right: 65px;
    width: 172px;
    height: 55px;
    z-index: 20;
    background-color: var(--light2);
    box-shadow: 0 0 15px var(--shadow);
    border: 1px solid var(--dark2);
    border-radius: 5px;
    padding: 16px 8px 16px 15px;
}

#side-buttons .message::before {
    content: "";
    position: absolute;
    background-color: var(--light2);
    border-bottom: 1px solid var(--dark2);
    border-right: 1px solid var(--dark2);
    right: -8px;
    top: 50%;
    margin-top: -4px;
    width: 15px;
    height: 15px;
    z-index: 18;
    transform: rotate(-45deg);
}

#side-buttons .message-contact {
    bottom: 70.5px;
    height: auto;
    width: 70px;
    padding: 10px 8px 10px 15px;
}

#side-buttons .message-contact::before {
    right: -9px;
    top: calc(50% - 4px);
}

/* Contact us */
#side-buttons .contact-us {
    bottom: 115px;
    background-color: var(--red1);
}

/* Scroll top */
#side-buttons .scroll-top {
    right: 10px;
    bottom: 70px;
    width: 30px;
    height: 30px;
    background-color: var(--dark9);
}

#side-buttons .scroll-top:hover {
    background-color: var(--dark5);
}

#side-buttons .scroll-top svg {
    color: var(--light1);
    font-size: 30px;
}

#side-buttons .scroll-top:hover svg {
    animation: none;
}