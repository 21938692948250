#page-cart {
    .page-content {
        width: 100%;
        background-color: var(--light3);
    }

    .content {
        margin: 0;
        padding: 15px 0 100px 0;
        width: 90vw;
        max-width: 1200px;
    }

    h2 {
        margin-bottom: 30px;
    }

    .cart-content {
        width: 100%;
    }

    .table {
        margin: 0;
        border: 1px solid var(--dark2);
        padding: 10px 0 0 0;
        min-width: 550px;
        height: fit-content;
    }

    .row {
        padding: 10px;

        img {
            width: 150px;
            object-fit: contain;
            cursor: pointer;
        }

        .name {
            margin: 5px 0;
            border-bottom: 1.5px solid transparent;
            font-size: 18px;
            text-wrap: nowrap;
            font-weight: 500;
            width: fit-content;
            cursor: pointer;

            a:first-of-type {
                margin-right: 5px;
            }

            a:last-of-type {
                font-size: 12px;
            }
        }

        .name:hover {
            border-color: var(--dark5);
        }

        .specs-div {
            color: var(--dark13);
            font-size: 14px;

            a:first-of-type {
                margin-right: 5px;
            }
        }

        .price-div {
            margin-top: 5px;
            width: 100%;

            .price {
                margin-right: 5px;
                font-size: 16px;
                opacity: 1;
            }

            .sale {
                font-size: 20px;
                color: var(--red);
            }
        }

        .quantity-cont {
            border: 1px solid var(--dark2);
            height: 26px;

            input {
                border: 0;
                width: 45px;
            }

            input:focus {
                outline: 0;
            }

            button {
                border: 0;
                background-color: var(--dark11-2);
                cursor: pointer;
            }

            button:first-of-type {
                border-right: 1px solid var(--dark2);
            }

            button:last-of-type {
                border-left: 1px solid var(--dark2);
            }
        }
    }

    /* No item */
    .no-item {
        margin: auto;
        padding-bottom: 20px;
        opacity: 0.7;
    }

    /* Items */
    .item {
        .item-content {
            width: 100%;
        }

        .info {
            width: calc(100% - 150px);
        }

        input {
            text-align: center;
            border: 1px solid var(--dark2);
            border-radius: 5px;
            padding: 3px 0;
            width: 80px;
            height: 20px;
            font-size: 14px;
            background-color: var(--light3);
        }

        input:focus {
            outline-color: var(--sub1);
        }

        .price {
            opacity: 0.6;
            text-decoration: line-through;
        }

        .delete {
            align-self: flex-start;
            margin: 0 10px;
            font-size: 30px;
            cursor: pointer;
        }

        .delete:hover {
            color: var(--sub1);
        }
    }

    /* Deal */
    .deals-list {
        margin-top: 5px;
        border-top: 1px solid var(--dark2);
        padding: 20px 0 10px 0;
        background-color: var(--dark11-2);

        .deals-content {
            margin-left: 35px;
        }

        .deal {
            margin-bottom: 10px;
            font-size: 12px;
    
            div {
                padding: 3px 6px 3px 10px;
                background-color: var(--sub4);
                color: var(--light1);
            }
    
            .text {
                padding: 3px 8px;
                color: var(--sub6);
                background-color: var(--sub4-4);
            }
    
            svg {
                margin-left: 2px;
            }
        }
    }


    /* Divider */
    .divider {
        align-self: center;
        width: 95%;
        border-bottom: 3px solid var(--dark4);
    }

    /* Summary */
    .summary {
        width: 200px;
        margin: 0 0 0 45px;
        padding-top: 10px;
        background-color: var(--dark4);

        .row {
            width: 100%;
            padding: 5px 0;
        }

        .bold {
            font-weight: bold;
        }

        .text {
            font-size: 18px;
            text-align: center;
        }

        .no-wrap {
            white-space: nowrap;
        }

        .save {
            color: var(--red);
        }

        .divider {
            margin: 10px 0;
            border-bottom: 2px solid var(--dark2);
            width: 100%;
        }

        .check-out {
            margin-top: 10px;

            .button1 {
                margin-bottom: 10px;
                border: 2px solid var(--google);
                border-radius: 5px;
                padding: 5px 15px;
                width: 120px !important;
                font-size: 18px;
                white-space: nowrap;
                text-decoration: none;
                text-align: center;
                background-color: var(--google);
            }

            .button1:hover {
                color: var(--light1);
                font-weight: normal;
                opacity: 0.8;
            }

            .no-item {
                background-color: var(--dark11-4);
                border: 1px solid var(--dark11-4);
                color: var(--dark5);
                cursor: not-allowed;
                opacity: 0.5;
            }

            .no-item:hover {
                font-weight: normal;
                color: var(--dark5);
                opacity: 0.5;
            }
        }
    }

    .summary.desktop {
        padding: 25px;
        height: fit-content;

        .text {
            font-size: 16px;
            width: auto;
        }

        .row {
            margin: 0;
        }

        .check-out .button1 {
            margin: 10px 0 0 0;
            font-size: 14px;
            width: 80px !important;
        }
    }

    @media only screen and (max-width: 925px) {
        .content {
            padding: 30px 0 50px 0;
            width: 100vw;

            h2 {
                margin: 0 0 20px 0;
            }
        }

        .table {
            border: 0;
            padding: 0;
            box-shadow: none;
            width: 100%;
            max-width: 650px;
            min-width: auto;
        }

        .row {
            padding: 5px 10px 10px 10px;

            .quantity-cont {
                height: 24px;

                button {
                    padding: 1px 4px;
                }

                input {
                    height: 18px;
                }
            }
        }

        .divider {
            margin: 15px 0;
        }

        .deals-list {
            margin-bottom: 5px;
            border-top: 0;
        }

        .summary {
            align-self: center;
            margin: 15px 0 0 0;
            border-top: 1px solid var(--dark5);
            padding-top: 10px;
            background-color: transparent;
            width: calc(100% - 20px);

            .check-out {
                margin-top: 25px;

                .button1 {
                    margin: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .content h2 {
            margin-bottom: 15px;
        }

        .row {
            margin-bottom: 10px;

            img {
                width: 150px;
                height: fit-content;
            }

            .info {
                width: calc(100vw - 230px);
            }

            .name {
                flex-direction: column;
                align-items: flex-start;
                text-wrap: wrap;

                a:last-of-type {
                    margin-top: 5px;
                }
            }

            .price-div {
                margin-top: 10px;

                .quantity-cont {
                    position: absolute;
                    right: 28px;
                    transform: translateY(-3px);
                }
            }
        }

        .no-item {
            margin-bottom: 0;
        }

        .summary {
            .row {
                margin: 0;
            }

            .check-out {
                margin-top: 15px;
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .row {
            margin-bottom: 20px;
            padding: 5px 0 10px 0;

            .item-content {
                justify-content: space-between;
            }

            img {
                margin: -8px -10px 0 0;
            }

            .info {
                justify-content: space-between;
                width: calc(100vw - 195px);
            }

            .name {
                margin: 0;
            }

            .specs-div {
                margin: 10px 0;
                text-wrap: nowrap;
                overflow: visible;
            }

            .price-div {
                flex-direction: column;
                margin: 0;

                .quantity-cont {
                    width: fit-content;
                    left: 30px;
                    right: 0;
                    transform: scale(0.8);
                }
            }
        }

        .deals-list  {
            justify-content: center;

            .deals-content {
                margin: 0;
                width: calc(100vw - 70px);
            }
        }

        .no-item {
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 370px) {
        .deals-list .deals-content {
            width: fit-content;
        }
    }
}