#page-catalog {
	.page-content {
		padding: 15px 60px 40px 60px;
		width: 100%;
		background-color: var(--light3);
	}

	button {
		width: 140px;
	}

	/* List content */
	.list-content {
		justify-content: center;
		grid-template-columns: repeat(auto-fill, 412px);
		flex-wrap: wrap;
		margin-top: 30px;
		width: 100vw;
		max-width: 1350px;

		.card-container {
			margin: 0 auto 60px auto;
			border: 1px solid var(--dark2);
			padding: 15px;
			background-color: var(--light3);
			box-shadow: 0 0 15px var(--shadow);
			width: fit-content;
		}

		.card-img-container {
			width: 300px;
			height: 491px;
		}

		.card-img {
			width: 300px;
		}

		.card-text {
			margin-top: 15px;
			font-weight: 600;

			svg {
				display: none;
				margin-left: 8px;
				font-size: 20px;
				cursor: pointer;
			}
		}
	}

	.list-content::after {
		content: "";
		flex: auto;
	}

	.card-container:hover .card-text svg {
		display: block;
	}

	.card-text svg:hover {
		color: var(--main);
	}

	.card-img-list {
		div {
			margin: 10px 10px 0 0;
			padding: 2px;
		}

		img {
			height: 50px;
			opacity: 0.6;
			cursor: pointer;
		}

		img:hover {
			opacity: 1;
		}
	}

	@media only screen and (max-width: 925px) {
		.page-content {
			padding: 30px 0;
		}

		h2 {
			margin-top: 0;
		}
	}

	@media only screen and (max-width: 500px) {
		.list-content .card-container {
			margin-bottom: 30px;
		}
	}

	@media only screen and (max-width: 374px) {
		.list-content {
			grid-template-columns: repeat(auto-fill, 310px);

			.card-img-container {
				width: 270px;
				height: 410px;
			}

			.card-img {
				width: 240px;
			}
		}
	}
}